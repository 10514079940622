export type Bet = {
    id: string,
    title: string,
    options: string[],
    tvl: string,
    tvlAmount: number,
    odds: string[],
    optionTVL: number[],
    optionShares: number[],
    resolvedOption: number,
    stoppedTakingBets?: boolean,
    createdAt: number,
    betType: number,
    userBettedOptions: number[],
    takeRate: number
}

export type Stream = {
    id: string,
    isStreamOver: boolean,
    bettedTVL: number,
    createdAt?: number,
    newStreamId?: string,
    streamTitle?: string,
    streamStartTime?: number,
    commentatorId?: string,
    isStreamingNatively?: boolean,
    streamerProfilePicUrl?: string,
    streamerDisplayName?: string
}

export type UnresolvedBet = {
    url: string,
    createdAt: number,
    title: string
}

export enum StreamCreationMode {
    CLOSED,
    IN_APP,
    EXTERNAL_LINK,
    FUTURE
}

export enum GIFT_TYPE {
    SUPER_CHAT = "SUPER_CHAT",
    WIF_STICKER = "WIF_STICKER",
    GIFT_COIN = "GIFT_COIN"
}

export enum STICKER_SUB_TYPE {
    WIF_SPACEMAN = "WIF_SPACEMAN",
    WIF_ANGER = "WIF_ANGER",
    WIF_POPCORN = "WIF_POPCORN",
    WIF_BELIEVE = "WIF_BELIEVE",
    WIF_LOL = "WIF_LOL",
    WIF_SUPREME = "WIF_SUPREME",
    WIF_LASER = "WIF_LASER",
    WIF_KING = "WIF_KING",
    WIF_MONEY = "WIF_MONEY"
}

export type TokenInfo = {
    address?: string,
    name?: string,
    symbol?: string,
    decimals?: number,
    balance?: string,
    uiBalance?: string,
    logo?: string
}

export enum BetType {
    REGULAR = 0,
    DECISION = 1
}