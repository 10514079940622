import { useEffect, memo } from 'react'
import rocketImage from './rocket.png'
import styles from './styles.module.css'
import { isOnMobile } from '../../utils/utils'

interface RocketLaunchProps {
  isLaunching: boolean
  setIsLaunching: (isLaunching: boolean) => void
  onClick?: () => void
}

const RocketLaunch = ({ isLaunching, setIsLaunching, onClick }: RocketLaunchProps) => {
  useEffect(() => {
    if (isLaunching) {
      setTimeout(() => {
        setIsLaunching(false)
      }, 5000);
    }
  }, [isLaunching])

  const isMobile = isOnMobile()

  return (
    <div
      onClick={onClick}
      className={`${styles.container}`}
      style={{
        display: isLaunching ? 'flex' : 'none',
        bottom: 0,
        right: Math.random() * (isMobile ? 10 : 20) + '%',
        transformOrigin: 'bottom right'
      }}
    >
      <div
        key={isLaunching ? 'launching' : 'idle'}
        className={`${styles.rocket} ${isLaunching ? styles.launch : ''}`}
      >
        <img
          src={`${window.location.protocol}//${window.location.host}/wif_space.webp`}
          alt="avatar"
          className={styles.avatar}
          style={{
            position: 'absolute',
            top: isMobile ? '20px' : '30px',
            left: '50%',
            transform: 'translateX(-50%)',
            width: isMobile ? '30px' : '40px',
            height: isMobile ? '30px' : '40px',
            borderRadius: '50%',
            zIndex: '1000000'
          }}
        />
        <img style={{
          width: isMobile ? '80px' : '120px'
        }} src={rocketImage} alt="rocket" />
        <div className={styles.fire}>
          <span></span>
          <span></span>
          <span></span>
        </div>
        {/* <div className={styles.particles}>
          {[...Array(20)].map((_, i) => (
            <span
              key={i}
              className={styles.particle}
              style={{
                '--offset-x': `${Math.random() * 100 - 50}px`,
                '--offset-y': `${Math.random() * 100 + 50}px`,
                animationDelay: `${Math.random() * 2}s`
              } as React.CSSProperties}
            ></span>
          ))}
        </div> */}
      </div>
    </div>
  )
}

export default memo(RocketLaunch)
