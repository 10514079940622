import axios from "axios";
import { trackLog } from "../utils/utils";
import { connectionType } from "../wallet/web3auth";

export const USER_TOKEN_KEY = 'USER_TOKEN_KEY'
export const REFERER_ID_KEY = 'REFERER_ID_KEY'
export const GUEST_ID_KEY = 'GUEST_ID_KEY'
export const GUEST_DISPLAY_NAME_KEY = 'GUEST_DISPLAY_NAME_KEY'

// const BASE_URL = 'http://localhost:8888/'
const BASE_URL = 'https://api.thelive.bet/'

export class HttpClient {
    static prefix(path: string): string {
        let prefix = connectionType.isWallet() ? 'WalletBearer' : 'Bearer'
        trackLog('HttpClient sending', path, prefix, new Date().getMilliseconds())
        return prefix

    }
    static async get<T>(path: string): Promise<T> {
        const userToken = localStorage.getItem(USER_TOKEN_KEY)
        return axios.get(BASE_URL + path, {
            headers: {
                "Cache-Control": "no-cache",
                "Authorization": this.prefix(path) + ' ' + (userToken == null ? "" : userToken!),
                "Content-Type": "application/json",
                "Accept": "application/json"
            },
        })
    }

    static async post<T>(path: string, data: any): Promise<T> {
        const userToken = localStorage.getItem(USER_TOKEN_KEY)
        return axios.post(BASE_URL + path, data, {
            headers: {
                "Cache-Control": "no-cache",
                "Authorization": this.prefix(path) + ' ' + (userToken == null ? "" : userToken!),
                "Content-Type": "application/json",
                "Accept": "application/json"
            }
        })
    }

}