import { useEffect, useState } from "react";
import { ChatMessage } from "../network/types";
import { AirdropClaimStatus } from "../network/types";
export function ClaimMessage(props: { message: ChatMessage, handleClaim: Function }) {
    const { message, handleClaim } = props
    const [count, setCount] = useState(message.expirationTimestamp ?
        Math.floor(message.expirationTimestamp / 1000) - Math.floor(Date.now() / 1000) :
        0)
    useEffect(() => {
        if (count <= 0) return;

        const timer = setTimeout(() => {
            setCount((c) => c - 1);
        }, 1000);

        return () => clearTimeout(timer);
    }, [count]);
    return <div className="chatMessageDisplayTextWithAirdrop">
        <div style={{ color: (message.color ? message.color : "#ffffff") }} className="chatMessageDisplayText">
            {message.text}
        </div>
        {
            (message.airdropClaimStatus === AirdropClaimStatus.UNCLAIMED) ? (
                <div className="claimAirdropText" onClick={() => handleClaim()}>Claim ({count}s)</div>
            ) : (
                (message.airdropClaimStatus === AirdropClaimStatus.CLAIMED) && (
                    <div className="claimedOrExpiredAirdropText">Claimed ({count}s)</div>
                )
            )
        }
    </div>
}