import { CHAIN_NAMESPACES, WALLET_ADAPTERS, WEB3AUTH_NETWORK } from "@web3auth/base";
import { Web3Auth, Web3AuthOptions } from "@web3auth/modal";
import { getInjectedAdapters } from "@web3auth/default-solana-adapter";

import { SolanaPrivateKeyProvider } from "@web3auth/solana-provider";
import { AuthAdapter } from "@web3auth/auth-adapter";
import { trackLog } from "../utils/utils";

export const RPC_URL = "https://mainnet.helius-rpc.com/?api-key=c9a79991-732d-4378-83c2-439220183bdb"
export const USDCAddress = 'EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v'
export const FEE_PAYER_AND_DEPOSIT_ADDRESS = '6cBapcq5zhwLfbiYgfA88ZYezWz2sNUq78fC59TLRRWX'
const clientId = "BO2TpJ7iss405l81odQa4NU4XjANm9wIB1mrT1WGVCuSxUwXhjTvbYA_wkeyVRTz6qubPY2Kdk1p5ugvV91pSB0"; // get from https://dashboard.web3auth.io

const chainConfig = {
    chainNamespace: CHAIN_NAMESPACES.SOLANA,
    chainId: "0x1", // Please use 0x1 for Mainnet
    rpcTarget: RPC_URL,
    displayName: "Solana Mainnet",
    blockExplorer: "https://explorer.solana.com",
    ticker: "SOL",
    tickerName: "Solana",
};

export class connectionType {
    static isWallet() {
        if (!localStorage.getItem('connectType') || localStorage.getItem('connectType') === 'auth') {
            return false
        }
        return true
    }
    static set(web3auth: Web3Auth) {
        if (web3auth.provider != null) {
            localStorage.setItem('connectType', web3auth.connectedAdapterName ?? '')
        }
    }
}

const privateKeyProvider = new SolanaPrivateKeyProvider({ config: { chainConfig } });

const web3AuthOptions: Web3AuthOptions = {
    clientId,
    web3AuthNetwork: WEB3AUTH_NETWORK.SAPPHIRE_MAINNET,
    uiConfig: {
        uxMode: "redirect",
        appName: "Live Bet",
        appUrl: "https://thelive.bet/",
        theme: {
            primary: "gray",
        },
        logoLight: "https://thelive.bet/favicon.ico",
        logoDark: "https://thelive.bet/favicon.ico",
        defaultLanguage: "en", // en, de, ja, ko, zh, es, fr, pt, nl, tr
        mode: "dark", // whether to enable dark mode. defaultValue: auto
        useLogoLoader: true,
    },
    privateKeyProvider: privateKeyProvider,
    sessionTime: 86400 * 7, // 7 days
    // useCoreKitKey: true,
};

export const web3auth = new Web3Auth(web3AuthOptions as Web3AuthOptions);

const authAdapter = new AuthAdapter({
    loginSettings: {
        mfaLevel: "none",
        curve: "ed25519", // allowed values "secp256k1" (default, EVM) or "ed25519" (Solana)
    },
    adapterSettings: {
        uxMode: "redirect", // "redirect" | "popup"
        whiteLabel: {
            logoLight: "https://thelive.bet/favicon.ico",
            logoDark: "https://thelive.bet/favicon.ico",
            defaultLanguage: "en", // en, de, ja, ko, zh, es, fr, pt, nl, tr
            mode: "dark", // whether to enable dark, light or auto mode. defaultValue: auto [ system theme]
        }
    },
    privateKeyProvider: privateKeyProvider
});
web3auth.configureAdapter(authAdapter)

export async function web3authInit() {
    trackLog('web3authInit called')
    try {
        // const openloginAdapter = new OpenloginAdapter({
        //   loginSettings: {
        //     mfaLevel: "none",
        //     curve: "ed25519", // allowed values "secp256k1" (default, EVM) or "ed25519" (Solana)
        //   },
        //   adapterSettings: {
        //     uxMode: "redirect", // "redirect" | "popup"
        //     whiteLabel: {
        //       logoLight: "https://thelive.bet/favicon.ico",
        //       logoDark: "https://thelive.bet/favicon.ico",
        //       defaultLanguage: "en", // en, de, ja, ko, zh, es, fr, pt, nl, tr
        //       mode: "light", // whether to enable dark, light or auto mode. defaultValue: auto [ system theme]
        //     },
        //   },
        // });
        // web3auth.configureAdapter(openloginAdapter);

        const adapters = await getInjectedAdapters({ options: web3AuthOptions })
        const phantomAdapter = adapters.find((e) => e.name === "phantom")
        if (phantomAdapter) {
            web3auth.configureAdapter(phantomAdapter)
        }
        await web3auth.initModal({
            modalConfig: {
                [WALLET_ADAPTERS.AUTH]: {
                    label: "openlogin",
                    loginMethods: {
                        google: {
                            name: "google",
                            showOnModal: true,
                            mainOption: true
                        },
                        discord: {
                            name: "discord",
                            showOnModal: true,
                            mainOption: true
                        },
                        twitter: {
                            name: "twitter",
                            showOnModal: false
                        },
                        farcaster: {
                            name: "farcaster",
                            showOnModal: false
                        },
                        apple: {
                            name: "apple",
                            showOnModal: false
                        },
                        wechat: {
                            name: "wechat",
                            showOnModal: false
                        },
                        twitch: {
                            name: "twitch",
                            showOnModal: false
                        },
                        facebook: {
                            name: "facebook",
                            showOnModal: false
                        },
                        reddit: {
                            name: "reddit",
                            showOnModal: false
                        },
                        weibo: {
                            name: "weibo",
                            showOnModal: false
                        },
                        line: {
                            name: "line",
                            showOnModal: false
                        },
                        linkedin: {
                            name: "linkedin",
                            showOnModal: false
                        },
                        kakao: {
                            name: "kakao",
                            showOnModal: false
                        },
                        github: {
                            name: "github",
                            showOnModal: false
                        },
                        // Disable email_passwordless and sms_passwordless
                        email_passwordless: {
                            name: "email_passwordless",
                            showOnModal: false
                        },
                        sms_passwordless: {
                            name: "sms_passwordless",
                            showOnModal: false
                        }
                    }
                }
            }
        });
    } catch (error) {
        console.error(error);
    }
}