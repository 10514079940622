import { SelectedChannel } from "../network/types"
import { getStreamType } from "./streamView"
import { Stream } from "../model/types"

export const updateStreamState = (stream: Stream, updatedRoomId: string, updateType: string) => {
    if (updatedRoomId.substring(0, 32) !== stream.id.substring(0, 32)) {
        return
    }
    if (updateType == 'ADD') {
        stream.isStreamingNatively = true
    } else if (updateType == 'DELETE') {
        stream.isStreamingNatively = false
    }
}

export const StreamFramePreview = (props: { stream: Stream, screenWidth: number }) => {
    const { stream, screenWidth } = props
    const id = stream.newStreamId ?? stream.id
    const streamType = getStreamType(id)
    if (streamType === SelectedChannel.KICK) {
        return <iframe className="previewStreamPlayer" src={`https://player.kick.com/${id!.split("~")[1]}?autoplay=false`} allowFullScreen={false} />
    } else if (streamType === SelectedChannel.YOUTUBE) {
        return <iframe className="previewStreamPlayer" src={`https://www.youtube.com/embed/${id!.split("~")[1]}?autoplay=0`} allow="autoplay" allowFullScreen={false} />
    } else if (streamType === SelectedChannel.TWITCH) {
        return <iframe className="previewStreamPlayer" src={`https://player.twitch.tv/?channel=${id!.split("~")[1]}&parent=thelive.bet`} allowFullScreen={false}> </iframe>
    } else if (streamType === SelectedChannel.STREAMED) {
        console.error(`https://embedme.top/embed/${id!.split("~")[1]}`)
        return <iframe className="previewStreamPlayer"
            allow="encrypted-media; picture-in-picture;"
            allowFullScreen={false}
            sandbox="allow-same-origin allow-scripts"
            src={`https://embedme.top/embed/${id!.split("~")[1]}`}></iframe>
    } else if (streamType === SelectedChannel.TWITTER) {
        return <div className="previewStreamPlayer" style={{
            marginBottom: "0.3rem",
            marginTop: (screenWidth <= 600 ? "0rem" : "-0.5rem")
        }}>
            <blockquote data-align="center" data-media-max-width={(screenWidth <= 600 ? 0.9 * screenWidth : 0.3 * screenWidth)} className="twitter-tweet" data-lang="en" data-dnt="true" data-theme="light">
                <a href={`https://twitter.com/${id!.split("~")[1]}/status/${id!.split("~")[2]}`}></a>
            </blockquote>
        </div>
    } else if (streamType === SelectedChannel.IN_APP) {
        return <div className="previewStreamPlayer">{previewStreamerBox(stream)}</div>
    }

    return <div className="previewStreamPlayer">Can't find this stream:{streamType}</div>
}

function previewStreamerBox(stream: Stream) {
    let stateInfo = stream.streamerDisplayName + ' is offline'
    let liveLabel = null
    if (stream.isStreamingNatively) {
        stateInfo = stream.streamerDisplayName + ' is LIVE'
        liveLabel = <div style={{ position: 'absolute', right: 20, top: 20, background: 'red', padding: '3px 7px', color: 'white', fontWeight: 'bold', borderRadius: 5 }}>LIVE</div>
    }
    return <div style={{ position: 'relative', width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        {liveLabel}
        <div style={{ display: 'flex', flexDirection: 'column', gap: 10, alignItems: 'center' }}>
            <div style={{ fontSize: '1.5rem' }}>{stream.streamTitle}</div>
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '0.4rem' }}>
                <img src={stream.streamerProfilePicUrl} style={{ width: '1.5rem', height: '1.5rem', borderRadius: '50%' }}></img>
                <div>{stateInfo}</div>
            </div>
        </div>
    </div>
}


export const StreamFrame = (props: { id: string, screenWidth: number, operationPanel: (id: string) => JSX.Element }) => {
    const { id, screenWidth, operationPanel } = props
    const streamType = getStreamType(id)
    if (streamType === SelectedChannel.KICK) {
        return <iframe className="streamPlayer" src={`https://player.kick.com/${id!.split("~")[1]}?autoplay=true}`} allowFullScreen={false} />
    } else if (streamType === SelectedChannel.YOUTUBE) {
        return <iframe className="streamPlayer" src={`https://www.youtube.com/embed/${id!.split("~")[1]}?autoplay=1}`} allow="autoplay" allowFullScreen={false} />
    } else if (streamType === SelectedChannel.TWITCH) {
        return <iframe className="streamPlayer" src={`https://player.twitch.tv/?channel=${id!.split("~")[1]}&parent=thelive.bet`} allowFullScreen={false}> </iframe>
    } else if (streamType === SelectedChannel.STREAMED) {
        console.error(`https://embedme.top/embed/${id!.split("~")[1]}`)
        return <iframe className="streamPlayer"
            allow="encrypted-media; picture-in-picture;"
            allowFullScreen={false}
            sandbox="allow-same-origin allow-scripts"
            src={`https://embedme.top/embed/${id!.split("~")[1]}`}></iframe>
    } else if (streamType === SelectedChannel.TWITTER) {
        return <div className="streamPlayer" style={{
            marginBottom: screenWidth <= 600 ? "0px" : "0px",
            marginTop: "0px"
        }}>
            <blockquote data-align="center" data-media-max-width={screenWidth} className="twitter-tweet" data-lang="en" data-dnt="true" data-theme="light">
                <a href={`https://twitter.com/${id!.split("~")[1]}/status/${id!.split("~")[2]}`}></a>
            </blockquote>
        </div>
    } else if (streamType === SelectedChannel.IN_APP) {
        return operationPanel(id)
    }

    return <div className="streamPlayer"><div className="spinner"></div></div>
}