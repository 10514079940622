import { useRef, useCallback, useState } from 'react'
import { createRoot } from 'react-dom/client'
import RocketLaunch from '../components/RocketLaunch'

export const useRocketLaunch = (containerRef: React.RefObject<HTMLElement>) => {
  const rootsRef = useRef<Map<string, any>>(new Map())
  const [isLaunchingRocket, setIsLaunchingRocket] = useState(false)
  const launchRocket = useCallback(() => {
    if (!containerRef.current) {
      alert("container is null")
      return
    }
    setIsLaunchingRocket(true)
    const rocketContainer = document.createElement('div')
    const id = `rocket-${Date.now()}`
    rocketContainer.id = id
    containerRef.current.insertBefore(rocketContainer, containerRef.current.firstChild)

    const root = createRoot(rocketContainer)
    rootsRef.current.set(id, root)

    root.render(
      <RocketLaunch
        isLaunching={true}
        setIsLaunching={(isLaunching) => {
          if (!isLaunching) {
            setTimeout(() => {
              setIsLaunchingRocket(false)
              root.unmount()
              rocketContainer.remove()
              rootsRef.current.delete(id)
            }, 100)
          }
        }}
      />
    )
  }, [containerRef])

  return { launchRocket, isLaunchingRocket }
} 