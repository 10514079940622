import Confetti from 'react-confetti'
import { RefObject, useEffect, useState } from 'react'
import { isOnMobile, trackLog } from '../utils/utils';
export const rainDuration = 5000 // *ms
const moneyIcon = new Image();
moneyIcon.src = '/wif_money.jpg'

export function Rain(props: { isRaining: boolean, containerRef: RefObject<HTMLDivElement> }) {
    const [width, setWidth] = useState(0)
    const [height, setHeight] = useState(0)
    const [numberOfPieces, setNumberOfPieces] = useState(50)
    useEffect(() => {
        if (isOnMobile()) {
            setNumberOfPieces(30)
        }
        const updateSize = () => {
            if (props.containerRef.current) {
                const element = props.containerRef.current;
                const rect = element.getBoundingClientRect();
                setWidth(rect.width)
                setHeight(rect.height)
                trackLog('wh', rect.width, rect.height)
            }
        }
        updateSize();
        window.addEventListener('resize', updateSize);
        return () => {
            window.removeEventListener('resize', updateSize);
        };
    }, []);
    return (
        props.isRaining ? <div style={{ zIndex: 998, backgroundClip: '#ccc', position: 'absolute', top: 0, left: 0 }}><Confetti
            width={width}
            height={height}
            numberOfPieces={numberOfPieces}
            gravity={0.08}
            drawShape={ctx => {
                ctx.beginPath()
                ctx.drawImage(moneyIcon, 0, 0, 70, 30)
                ctx.stroke()
                ctx.closePath()
            }}
        /></div> : <></>
    )
}