import { isChrome } from '../utils/utils'
import './askPwa.css'
export function askPwaView() {
    const shareImg = isChrome() ? <img src="/askpwa_share_chrome.png" /> : <img src="/askpwa_share_safari.png" />
    return <div className='askPwaContainer'>
        <div className='askPwaLogo'>
            <img src="/favicon.ico" />
            <div style={{ fontSize: "25px", fontWeight: "bold" }}>Live Bet</div>
        </div>
        <div className='askPwaBody'>
            <div className='askPwaText'>
                <div className='askPwaTextHead'>Add to Home Screen</div>
                <div className='askPwaTextBody'>Enjoy faster load times and smoother interactions by downloading the app to your home screen.</div>
            </div>

            <div className='askPwaLine'></div>

            <div className='askPwaImg'>
                <div className='askPwaImgContainer'>{shareImg}</div>
                <div className='askPwaImgDes'>Click on share button and add to home screen</div>
            </div>

            <div className='askPwaImg'>
                <div className='askPwaImgContainer'><img src="/askpwa_add_to_homescreen.png" /></div>
                <div className='askPwaImgDes'>Click on "Add to Home Screen" in the menu</div>
            </div>
        </div>
    </div>
}
