export const getFormattedDate = (date: Date): string => {
    let day
    switch(date.getDay()) {
      case 0:
        day = 'Sunday';
        break;
      case 1:
        day = 'Monday';
        break;
      case 2:
        day = 'Tuesday';
        break;
      case 3:
        day = 'Wednesday';
        break;
      case 4:
        day = 'Thursday';
        break;
      case 5:
        day = 'Friday';
        break;
      case 6:
        day = 'Saturday';
        break;
    }

    return `${date.getMonth() + 1}/${date.getDate()} (${day})`
  }

  export const getFormattedTime = (timestamp: number): string => {
    const timezoneOffset = new Date().getTimezoneOffset()
    const date = new Date(timestamp + timezoneOffset)

    let hours = date.getHours()
    const minutes = date.getMinutes()
    const ampm = hours >= 12 ? 'PM' : 'AM';
  
    // Convert to 12-hour format
    hours = hours % 12
    // the hour '0' should be '12'
    hours = hours ? hours : 12
  
    const minutesStr = minutes < 10 ? '0' + minutes : minutes
    return `${hours}:${minutesStr} ${ampm}`
  }