import { useEffect, useState } from "react";
import { TokenInfo } from "../model/types";
import { isOnMobile } from "../utils/utils";

export function StreamCoinTips(props: { streamerCoinTips: TokenInfo[], isRoomInitializedForCoinAnimation: boolean }) {
    useEffect(() => {

    }, [props.streamerCoinTips])

    return <div className="streamerCoinTipsEarningContainer">
        <div className="inStreamEarningText">Coin Tips: </div>
        {
            props.streamerCoinTips.map((streamerCoin) => <StreamerCoinTipsEarningItem streamerCoin={streamerCoin} key={streamerCoin.name} isRoomInitializedForCoinAnimation={props.isRoomInitializedForCoinAnimation} />)
        }
    </div>
}

function StreamerCoinTipsEarningItem(props: { streamerCoin: TokenInfo, isRoomInitializedForCoinAnimation: boolean }) {
    const [prev, setPrev] = useState(0)
    const [inc, setInc] = useState(0)
    const fontSize = isOnMobile() ? '1rem' : '1.1rem'
    useEffect(() => {
        let newBalance = parseFloat(props.streamerCoin.balance ?? '0')
        setPrev(newBalance)
        let change = newBalance - prev
        if (props.isRoomInitializedForCoinAnimation) {
            setInc(change)
        }
        const timer = setTimeout(() => {
            setInc(0);
        }, 2000);

        return () => clearTimeout(timer);
    }, [props.streamerCoin])
    return <div className="streamerCoinTipsEarningItem">
        {props.isRoomInitializedForCoinAnimation && inc > 0 && <div className="streamerCoinTipsEarningItemPop" key={prev} style={{ fontSize }}>
            <span>+</span>
            <img src={props.streamerCoin.logo} style={{ width: 25, height: 25 }} />
            {inc.toFixed(2)}
        </div>}
        <img className="streamerCoinTipsEarningImage" src={props.streamerCoin.logo}></img>
        <div className="inStreamEarningText">{props.streamerCoin.uiBalance}</div>
    </div>
}
