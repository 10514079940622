// detect user agent
export function isOnMobile() {
    const userAgent = navigator.userAgent || navigator.vendor;
    return /android|iphone|ipad|ipod|windows phone/i.test(userAgent)
}

// detect chrome
export function isChrome() {
    const userAgent = navigator.userAgent;
    const isAndroidChrome = /Chrome/.test(userAgent);
    const isIosChrome = /CriOS/.test(userAgent) && /iPhone|iPad|iPod/.test(userAgent);
    return isAndroidChrome || isIosChrome;
}

export function isPwa() {
    // tmp code, for testing
    if (navigator.userAgent.match(/Kit\/537\.36/)) {
        return true
    }
    return window.matchMedia('(display-mode: standalone)').matches || (window.navigator as any).standalone;
}

export function isNumber(value?: string | number): boolean {
    return ((value != null) && (value !== '') && !isNaN(Number(value.toString())));
}

export function trackLog(...msg: any[]) {
    console.log('%ctrack', 'color: #ccc;', ...msg)
}