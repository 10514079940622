import { useEffect, useState } from "react"
import { isOnMobile, trackLog } from "../utils/utils"

export function InStreamEarning(props: { userUsdEarnings: number | undefined }) {
    const [inc, setInc] = useState(0)
    const [prev, setPrev] = useState(0)
    const [times, setTimes] = useState(0)
    const [popClass, setPopClass] = useState<'iecp_green' | 'iecp_red'>('iecp_green')
    const fontSize = isOnMobile() ? '1rem' : '1.1rem'
    useEffect(() => {
        if (typeof props.userUsdEarnings != 'undefined') {
            trackLog('set pops', props.userUsdEarnings, inc, prev, times)
            setPrev(props.userUsdEarnings)
            let change = props.userUsdEarnings - prev
            setTimes(times + 1)
            setInc(change)
            if (change > 0) {
                trackLog('set green')
                setPopClass('iecp_green')
            } else {
                trackLog('set red')
                setPopClass('iecp_red')
            }
        }
    }, [props.userUsdEarnings])
    return <div className="inStreamEarningContainer">
        {times > 1 && inc != 0 && <div className={"inStreamEarningContainerPop " + popClass} key={prev} style={{ fontSize }}>
        ${inc > 0 && '+'}{inc.toFixed(2)}
        </div>}
        <div className="inStreamEarningText">In-stream Earnings:</div>
        <img className="betBoxCoinImage" src="/coin.png" />
        <div className="inStreamEarningText">{props.userUsdEarnings?.toFixed(2) ?? '0.00'}</div>
    </div>
}