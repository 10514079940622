import { useLocation, useParams, useSearchParams } from "react-router-dom";
import "./streamView.css";
import { ZIM, ZIMEventOfReceiveConversationMessageResult, ZIMEventOfRoomStateChangedResult, ZIMMessage, ZIMRoomAdvancedConfig, ZIMRoomState, ZIMSDK } from "zego-zim-web";
import { useEffect, useRef, useState } from "react";
import { Bet, BetType, GIFT_TYPE, STICKER_SUB_TYPE, Stream, TokenInfo } from "../model/types";
import { toast } from "react-toastify";
import { GUEST_DISPLAY_NAME_KEY, HttpClient } from "../network";
import { AirdropClaimStatus, BetResponse, ChatMessage, ClaimAirdropResponse, CreateBetOrStreamResponse, MessageBody, MessageBodyType, PlaceBetResponse, Response, RoomResponse, SelectedChannel, UpdateRoomCommentatorIdResponse, UpdateRoomLivenessResponse, UpdateStreamResponse } from "../network/types";
import { isUserLoggedIn } from "../utils/login";
import { formatTimestamp, getExternalStreamLink } from "../utils/stream";
import { ZegoExpressEngine } from 'zego-express-engine-webrtc'
import ZegoLocalStream from "zego-express-engine-webrtc/sdk/code/zh/ZegoLocalStream.web"
import { trackLog } from "../utils/utils";
import { UnmuteButton } from './unmuteButton';
import { BulletCanvas, bulletMessage, maxFloor } from "./bullet";
import { useRocketLaunch } from '../hooks/useRocketLaunch';
import { Connection, PublicKey } from '@solana/web3.js';

import { Rain, rainDuration } from "./animation";
import { RPC_URL, USDCAddress } from "../wallet/web3auth";
import axios from "axios";
import { GiftPopup } from "./giftPopup";
import { TOKEN_PROGRAM_ID } from "@solana/spl-token";
import { Tooltip } from "react-tooltip";
import { InStreamEarning } from "./inStreamEarning";
import { StreamCoinTips } from "./streamCoinTips";
import { AirdropPopup } from "./airdropPopup";
import { ClaimMessage } from "./claimMessage";
import { StreamFrame } from "./streamFrame";

export const IN_APP_USD_FAKE_ADDRESS = 'IN_APP_USD_FAKE_ADDRESS'

export const getStreamType = (id: string | undefined): SelectedChannel => {
  if (id && id!.startsWith("kick~")) {
    return SelectedChannel.KICK
  } else if (id && id!.startsWith("youtube~")) {
    return SelectedChannel.YOUTUBE
  } else if (id && id!.startsWith("twitch~")) {
    return SelectedChannel.TWITCH
  } else if (id && id!.startsWith("streamed~")) {
    return SelectedChannel.STREAMED
  } else if (id && id!.startsWith("twitter~")) {
    return SelectedChannel.TWITTER
  } else if (id && id!.startsWith("featured~")) {
    return SelectedChannel.FEATURED
  } else if (id && id!.startsWith("inapp~")) {
    return SelectedChannel.IN_APP
  }

  return SelectedChannel.UNKNOWN
}

const getStreamPlayerClassName = (id: string): 'hostStreamPlayer' | 'commentateStreamPlayer' => {
  if (getStreamType(id) == SelectedChannel.IN_APP) {
    return 'hostStreamPlayer'
  }
  return 'commentateStreamPlayer'
}

export const getStreamId = (stream: Stream | undefined) => {
  return (stream?.newStreamId && stream?.newStreamId !== '') ? stream?.newStreamId : stream?.id
}

function formatDateTime(date: Date): string {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  const hours = String(date.getHours()).padStart(2, '0');
  const minutes = String(date.getMinutes()).padStart(2, '0');
  return `${year}${month}${day}T${hours}${minutes}00`;
}

export const getMessageIcon = (message: ChatMessage) => {
  switch (message.type) {
    case MessageBodyType.RESOLVED_BET:
      return <div className="chatMessagePfpImage">✅</div>
    case MessageBodyType.ADD_BET:
      return <div className="chatMessagePfpImage">╋</div>
    case MessageBodyType.PLACED_BET:
      return <div className="chatMessagePfpImage">💰</div>
    case MessageBodyType.TRENDING_BET:
      return <div className="chatMessagePfpImage">🔥</div>
    case MessageBodyType.UPDATE_STREAM_LINK:
      return <div className="chatMessagePfpImage">🔗</div>
    case MessageBodyType.STOP_BET:
      return <div className="chatMessagePfpImage">🕒</div>
    case MessageBodyType.UPDATE_COMMENTATOR:
      return <div className="chatMessagePfpImage">🎙️</div>
    case MessageBodyType.SEND_GIFT:
      return <img className="chatMessagePfpImageGift" src={message.userPfpUrl} />
    default:
      return <img className="chatMessagePfpImage" src={message.userPfpUrl} />
  }
}

export const getAddToCalendarButton = (isPreview: boolean, title: string, timestamp: number, link: string) => {
  const startDateTime = new Date(timestamp)
  const endDateTime = new Date(startDateTime.getTime() + 60 * 60 * 1000) // Add 1 hour

  const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
  const userAgent = navigator.userAgent || navigator.vendor;
  const timeRange: string = `${formatDateTime(startDateTime)}/${formatDateTime(endDateTime)}`;

  const isOnMobile = /android|iphone|ipad|ipod|windows phone/i.test(userAgent)
  const params = {
    action: 'TEMPLATE',
    details: link,
    text: title,
    ctx: userTimeZone,
    dates: timeRange
  }
  const urlParams = new URLSearchParams(params).toString();

  const url = 'https://calendar.google.com/calendar/render?' + urlParams
  return <div className={isPreview ? "previewSubscribeButton" : "btn streamSubscribeButton"} onClick={(event) => {
    window.open(url, isOnMobile ? '_self' : '_blank')
    return event.stopPropagation()
  }}>Subscribe</div>
}

enum PublishingState {
  REQUESTING_PUBLISH,
  PUBLISHING,
  NOT_PUBLISHING
}

const HAS_SEEN_COMMENTATOR_TUTORIAL_POPUP_KEY = 'HAS_SEEN_COMMENTATOR_TUTORIAL_POPUP_KEY'
const HAS_SEEN_GIFTING_STICKER_TUTORIAL_POPUP_KEY = 'HAS_SEEN_GIFTING_STICKER_TUTORIAL_POPUP_KEY'
const HAS_SEEN_SUPERCHAT_TUTORIAL_POPUP_KEY = 'HAS_SEEN_SUPERCHAT_TUTORIAL_POPUP_KEY'

function StreamView(props: any) {
  const location = useLocation()
  let { id } = useParams();
  let zim: ZIMSDK = props.zim
  let zegoExpressEngine: ZegoExpressEngine = props.zegoExpressEngine
  let zimToken = props.zimToken
  let isIMLoggedIn = props.isIMLoggedIn
  let login = props.login
  let userAddress = props.userAddress
  let guestId = props.guestId
  let userCoins = props.userCoins
  let setUserCoins = props.setUserCoins
  let setUserPoints = props.setUserPoints
  let updateUserCoinBalance = props.updateUserCoinBalance
  let isResolver = props.isResolver
  let isCommunityMod = props.isCommunityMod
  let displayName = props.displayName
  let profilePicUrl = props.profilePicUrl
  let screenWidth = props.screenWidth
  let betCreationTakeRate = props.betCreationTakeRate
  let betCreationCost = props.betCreationCost
  let overallTakeRate = props.overallTakeRate
  let overallDecisionTakeRate = props.overallDecisionTakeRate
  let decisionCommentatorTakeRate = props.decisionCommentatorTakeRate
  let giftPrice = props.giftPrice
  let web3auth = props.web3auth
  let mixpanel = props.mixpanel
  let connection: Connection = props.connection
  const localStreamRef = useRef<ZegoLocalStream | null>(null)
  const mediaStreamRef = useRef<MediaStream | null>(null)
  const [searchParams, setSearchParams] = useSearchParams();
  const [isCreatingBet, setIsCreatingBet] = useState<boolean>(false);
  const [isCreatingBetOnBackend, setIsCreatingBetOnBackend] = useState<boolean>(false);
  const [isPlacingBetOnBackend, setIsPlacingBetOnBackend] = useState<boolean>(false);
  const [inCreateBetDisplayName, setInCreateBetDisplayName] = useState<string>("");
  const [inCreateBetOptions, setInCreateBetOptions] = useState<string[]>(["", ""]);
  const [inCreateBetType, setInCreateBetType] = useState<BetType>(BetType.REGULAR);
  const [roomId, setRoomId] = useState<string>("");
  const [streamId, setStreamId] = useState<string>("");
  const [streamCommentatorId, setStreamCommentatorId] = useState<string | undefined>("");
  const [streamCommentatorDisplayName, setStreamCommentatorDisplayName] = useState<string | undefined>("");
  const [streamCommentatorProfilePicUrl, setStreamCommentatorProfilePicUrl] = useState<string | undefined>("");
  const [userUsdEarnings, setUserUsdEarnings] = useState<number | undefined>();
  const [streamerCoinTips, setStreamerCoinTips] = useState<TokenInfo[] | undefined>();
  const [streamCreatorId, setStreamCreatorId] = useState<string | undefined>("");
  const [streamTitle, setStreamTitle] = useState<string | undefined>("");
  const roomIdRef = useRef<string>()
  const localStreamPlayerRef = useRef<HTMLDivElement>(null);
  const remoteStreamPlayerRef = useRef<HTMLDivElement>(null);
  roomIdRef.current = roomId
  const [bets, setBets] = useState<Bet[]>([]);
  const [trendingBet, setTrendingBet] = useState<Bet>();
  const [isStreamOver, setIsStreamOver] = useState<boolean>(true);
  const [streamStartTime, setStreamStartTime] = useState<number | undefined>();
  const [isShowingTrendingBet, setIsShowingTrendingBet] = useState<boolean>(true);
  const [selectedBetId, setSelectedBetId] = useState<string>("");
  const [selectedBetOptionIndex, setSelectedBetOptionIndex] = useState<number>(-1);
  const [selectedBetIdForSharing, setSelectedBetIdForSharing] = useState<string>("");
  const [selectedBetIdForMod, setSelectedBetIdForMod] = useState<string>("");
  const [selectedResolveOptionIndex, setSelectedResolveOptionIndex] = useState<number>(-1);
  const [isShowingSharePopup, setIsShowingSharePopup] = useState<boolean>(false);
  const [placedBetAmount, setPlacedBetAmount] = useState<number>(10);
  const [afterBetWinningCoinsAmount, setAfterBetWinningCoinsAmount] = useState<number>(0);
  const [countdownRemainingTime, setCountdownRemainingTime] = useState<number>(0);
  const [isMarkingTrendingBet, setIsMarkingTrendingBet] = useState<boolean>(false);
  const [isShowingStopBetPopup, setIsShowingStopBetPopup] = useState<boolean>(false);
  const [isShowingMarkStreamLiveStatusPopup, setIsShowingMarkStreamLiveStatusPopup] = useState<boolean>(false);
  const [isShowingSwapStreamLinkPopup, setIsShowingSwapStreamLinkPopup] = useState<boolean>(false);
  const [isShowingUpdateCommentatorPopup, setIsShowingUpdateCommentatorPopup] = useState<boolean>(false);
  const [isShowingReportStreamPopup, setIsShowingReportStreamPopup] = useState<boolean>(false);
  const [isShowingCommentatorTutorialPopup, setIsShowingCommentatorTutorialPopup] = useState<boolean>(false);
  const [isShowingGiftingRocketTutorialPopup, setIsShowingGiftingRocketTutorialPopup] = useState<boolean>(false);
  const [giftingSelectedSubtype, setGiftingSelectedSubtype] = useState<string>();
  const [isShowingSuperChatTutorialPopup, setIsShowingSuperChatTutorialPopup] = useState<boolean>(false);
  const [inCreateSwapStreamUrl, setInCreateSwapStreamUrl] = useState<string>("");
  const [inCreateReportStreamReason, setInCreateReportStreamReason] = useState<string>("");
  const [inUpdateCommentatorAddress, setInUpdateCommentatorAddress] = useState<string>("");
  const [isResolvingBet, setIsResolvingBet] = useState<boolean>(false);
  const [publishingState, setPublishingState] = useState<PublishingState>(PublishingState.NOT_PUBLISHING);
  const [isPlayingStream, setIsPlayingStream] = useState<boolean | undefined>(undefined);
  const [hasCommentatorVideoOn, setHasCommentatorVideoOn] = useState<boolean>(true);
  const [isTogglingCommentatorVideo, setIsTogglingCommentatorVideo] = useState<boolean>(false);
  const [chatBoxContent, setChatBoxContent] = useState("");
  const [chatMessages, setChatMessages] = useState<ChatMessage[]>([]);
  const [roomLiveUserCount, setRoomLiveUserCount] = useState<number>(0);
  const streamerRoomChatListRef = useRef<HTMLDivElement>(null);
  const popupContainerRef = useRef<HTMLDivElement>(null);
  const [bulletMessages, setBulletMessages] = useState<bulletMessage[]>([])

  const [isRaining, setIsRaining] = useState(false)
  const rainTimeoutId = useRef<any>(null);

  const [isShowingGiftPopup, setIsShowingGiftPopup] = useState(false)
  const [giftPopupIsLoading, setGiftPopupIsLoading] = useState(false)
  const [giftPopupInfoList, setGiftPopupInfoList] = useState<{
    [key: string]: TokenInfo;
  }>({})
  const [isInitiallyStreaming, setIsInitiallyStreaming] = useState(false)
  const [isShowingAirdropPopup, setIsShowingAirdropPopup] = useState(false)
  const [airdropPopupIsLoading, setAirdropPopupIsLoading] = useState(false)
  const [airdropPopupInfoList, setAirdropPopupInfoList] = useState<{
    [key: string]: TokenInfo;
  }>({})
  const [streamerProfilePicUrl, setStreamerProfilePicUrl] = useState('')
  const [streamerDisplayName, setStreamerDisplayName] = useState('')
  const [isRoomInitializedForCoinAnimation, setIsRoomInitializedForCoinAnimation] = useState(false)
  const handleNewBulletMessage = (newBulletMessage: bulletMessage) => {

    setBulletMessages((preMessage) => {
      let lastMessage = preMessage[preMessage.length - 1]
      if (lastMessage != undefined) {
        if ((newBulletMessage.createdAt - lastMessage.createdAt) < 3) {
          newBulletMessage.floor = lastMessage.floor + 1
          if (newBulletMessage.floor >= maxFloor) {
            newBulletMessage.floor = 0
          }
        }
      }

      if (newBulletMessage.floor > 0) {
        let firstMessage = preMessage[preMessage.length - newBulletMessage.floor]
        if (firstMessage != undefined) {
          if ((newBulletMessage.createdAt - firstMessage.createdAt) >= 3) {
            // got a seat
            newBulletMessage.floor = 0
          }
        }
      }

      return [...preMessage, newBulletMessage]
    })

    setTimeout(() => {
      // remove this message from bullet msg list after a while
      setBulletMessages((prevMessages) => prevMessages.slice(1));
    }, 12_000);
  }

  useEffect(() => {
    trackLog('listen receiveRoomMessage on address: ', userAddress)
    zim.on('receiveRoomMessage', (zim: ZIMSDK, message: ZIMEventOfReceiveConversationMessageResult) => {
      handleMessage(message.messageList, userAddress)
    });
  }, [])

  useEffect(() => {
    zim.on('roomStateChanged', (zim: ZIMSDK, roomStateChangedResult: ZIMEventOfRoomStateChangedResult) => {
      handleRoomStateChange(roomStateChangedResult)
    })
    zim.on('error', (e: any) => { console.log("!!!!!", { e }) })
    zegoExpressEngine.on('publisherStateUpdate', (result) => {
      trackLog('result.state', result?.state)
      console.error(result.state)
      if (result.state === 'PUBLISHING') {
        setPublishingState(PublishingState.PUBLISHING)
      } else if (result.state === 'NO_PUBLISH') {
        setPublishingState(PublishingState.NOT_PUBLISHING)
      }
    });
    zegoExpressEngine.on('roomStreamUpdate', async (streamRoomID, updateType, streamList, extendedData) => {
      trackLog("streamView/roomStreamUpdate called ", streamRoomID, updateType)
      if (streamRoomID.substring(0, 32) !== id!.substring(0, 32)) {
        trackLog("roomStreamUpdate room id doesn't match", { streamRoomID, roomId })
        return
      }
      trackLog('roomStreamUpdate(streamRoomID, updateType)', streamRoomID, updateType)
      if (updateType == 'ADD') {
        startPlayingStream()
      } else if (updateType == 'DELETE') {
        stopPlayingStream()
      }
    })
    zegoExpressEngine.on('remoteCameraStatusUpdate', async (streamRoomID, on) => {
      if (streamRoomID.substring(0, 32) !== id!.substring(0, 32)) {
        trackLog("remoteCameraStatusUpdate room id doesn't match", { streamRoomID, id })
        return
      }
      trackLog('remoteCameraStatusUpdate(streamRoomID, on)', streamRoomID, on)
      setHasCommentatorVideoOn(on === "OPEN")
    })

    fetchRoomInfo()
  }, [id, publishingState])

  useEffect(() => {
    if (publishingState == PublishingState.PUBLISHING) {
      return () => {
        stopPublishingStream()
      }
    }
  }, [publishingState])

  useEffect(() => {
    const handleLeavePage = () => {
      trackLog("Leaving the StreamView", location.pathname);
      zegoExpressEngine.off('roomStreamUpdate')
      stopPlayingStream()
    };

    return () => {
      handleLeavePage();
    };
  }, [id, publishingState]);

  useEffect(() => {
    if (isIMLoggedIn && roomId) {
      enterRoom()
    }
  }, [isIMLoggedIn, roomId]);

  useEffect(() => {
    let userID: string
    if (userAddress && userAddress !== "") {
      userID = userAddress.substring(0, 32)
    } else {
      userID = guestId.substring(0, 32)
    }
    if (zimToken && roomId && (userAddress || guestId)) {
      zegoExpressEngine.logoutRoom()
      zegoExpressEngine.loginRoom(id!, zimToken, { userID: userID }, { userUpdate: true })
        .then((response) => {
          console.log("zegoExpressEngine logined room", { roomId, userAddress, userID })
        })
        .catch((err) => {
          console.error("zegoExpressEngine loginRoom", err)
          toast.error(err)
        })
    }
  }, [zimToken, roomId, userAddress, guestId]);

  useEffect(() => {
    const updateTimers = () => {
      const currentTime = Date.now();

      if (getStreamType(streamId) === SelectedChannel.FEATURED) {
        setCountdownRemainingTime(Math.max(streamStartTime! - currentTime, 0))
      }
    };

    // Initialize and update every second
    const interval = setInterval(() => {
      updateTimers();
    }, 1000);

    // Cleanup on component unmount
    return () => clearInterval(interval);
  }, [streamId, streamStartTime]);

  useEffect(() => {
    if (bets.length > 0) {
      bets.sort((betA, betB) => {
        if (betA.resolvedOption != betB.resolvedOption) {
          return (betA.resolvedOption < 0) ? -1 : 1
        }

        if (betA.createdAt != betB.createdAt) {
          return (betA.createdAt < betB.createdAt) ? 1 : -1
        }

        return betA.id.localeCompare(betB.id)
      })
      const queryBetId = searchParams.get('bid')

      if (queryBetId) {
        if (!trendingBet || trendingBet.id !== queryBetId) {
          const queryBet = bets.find((bet) => bet.id === queryBetId)
          if (queryBet) {
            setIsShowingTrendingBet(false)

            setTimeout(() => {
              const element = document.getElementById("betid~" + queryBetId)

              if (element) {
                element.scrollIntoView({ behavior: 'smooth' });
              }
            }, 50)
          }
        }

        setSearchParams("")
      }
    }
  }, [bets]);

  const enterRoom = () => {
    if (isIMLoggedIn) {
      zim.enterRoom({ roomID: id!.substring(0, 32)!, roomName: id!.substring(0, 32)! }, {} as ZIMRoomAdvancedConfig)
        .then(() => {
          console.log("Logged into room " + roomId)

          setInterval(updateLiveUserCount, 3000 + Math.floor(Math.random() * 3000))
        })
        .catch((e) => {
          console.log("failed to log into room", { e })
        })
    } else {
      console.log("enterRoom in 1000ms because user isn't logged in")
      setTimeout(enterRoom, 1000)
    }
  }

  const clearLocalStream = () => {
    if (localStreamRef.current) {
      zegoExpressEngine.destroyStream(localStreamRef.current)
    }

    localStreamRef.current = null
    setHasCommentatorVideoOn(true)
    setIsPlayingStream(false)
    setIsTogglingCommentatorVideo(false)
  }

  const startCommentateStreamOrShowTutorial = () => {
    const hasSeenTutorial = localStorage.getItem(HAS_SEEN_COMMENTATOR_TUTORIAL_POPUP_KEY)
    if (!hasSeenTutorial || hasSeenTutorial !== 'true') {
      setIsShowingCommentatorTutorialPopup(true)
      localStorage.setItem(HAS_SEEN_COMMENTATOR_TUTORIAL_POPUP_KEY, 'true')
    } else {
      startCommentateStream()
    }
  }

  const dismissTutorialAndStartCommentatorStream = () => {
    setIsShowingCommentatorTutorialPopup(false)
    startCommentateStream()
  }

  const startInAppStream = async () => {
    trackLog('startInAppStream called')
    await HttpClient.post<Response<UpdateStreamResponse>>('room/start_in_app_stream', {
      roomId: roomId
    })
      .then(async (response) => {
        const data = response.data

        trackLog('startCommentateStream called')
        try {
          setPublishingState(PublishingState.REQUESTING_PUBLISH)
          localStreamRef.current = await zegoExpressEngine.createZegoStream({
            videoBitrate: 300,
            audioBitrate: 48,
            camera: {
              audio: true,
              video: {
                quality: 1,
                width: 320,
                height: 240,
                frameRate: 15
              }
            }
          });
          zegoExpressEngine.setCaptureVolume(localStreamRef.current, 100)
          zegoExpressEngine.startPublishingStream(id!.substring(0, 32), localStreamRef.current, { videoCodec: 'H264' });
          localStreamRef.current.playVideo(localStreamPlayerRef.current!, {
            mirror: false,
            objectFit: "cover",
          })

          return true
        } catch (err) {
          trackLog(err)
          setPublishingState(PublishingState.NOT_PUBLISHING)
          console.error(err)
          return false
        }
      })
      .catch((e) => {
        toast.error("Failed to start stream. Try again. (Reason: " + e.message + '"');
      })
  }

  const startCommentateStream = async () => {
    trackLog('startCommentateStream called')
    try {
      setPublishingState(PublishingState.REQUESTING_PUBLISH)
      localStreamRef.current = await zegoExpressEngine.createZegoStream({
        videoBitrate: 300,
        audioBitrate: 48,
        camera: {
          audio: true,
          video: {
            quality: 1,
            width: 320,
            height: 240,
            frameRate: 15
          }
        }
      });
      zegoExpressEngine.setCaptureVolume(localStreamRef.current, 100)
      zegoExpressEngine.startPublishingStream(id!.substring(0, 32), localStreamRef.current, { videoCodec: 'H264' });
      localStreamRef.current.playVideo(localStreamPlayerRef.current!, {
        mirror: false,
        objectFit: "cover",
      })

      return true
    } catch (err) {
      trackLog(err)
      setPublishingState(PublishingState.NOT_PUBLISHING)
      console.error(err)
      return false
    }
  }

  async function stopPublishingStream() {
    trackLog('stopPublishingStream called', publishingState, PublishingState.PUBLISHING)
    if (publishingState === PublishingState.PUBLISHING) {
      trackLog('stopping stream')
      zegoExpressEngine.stopPublishingStream(id!.substring(0, 32))
      clearLocalStream()

      if (getStreamType(streamId) === SelectedChannel.IN_APP) {
        HttpClient.post<Response<UpdateRoomLivenessResponse>>('room/markStreamOver', {
          roomId: roomId,
          isStreamOver: true
        })
          .then((response) => {
            fetchRoomInfo()
          })
          .catch((e) => {
            console.log("!!!!failed to mark stream as over", { e })
          })
      }
    }
  }

  const clearRemoteStream = () => {
    mediaStreamRef.current = null
    setHasCommentatorVideoOn(true)
    setIsPlayingStream(false)
    setIsInitiallyStreaming(false)
    setIsTogglingCommentatorVideo(false)
  }

  async function startPlayingStream() {
    trackLog('startPlayingStream called')
    try {
      setIsPlayingStream(true)
      mediaStreamRef.current = await zegoExpressEngine.startPlayingStream(id!.substring(0, 32), {});
      const remoteView = zegoExpressEngine.createRemoteStreamView(mediaStreamRef.current);
      remoteView.play(remoteStreamPlayerRef.current!, {
        objectFit: "cover",
        enableAutoplayDialog: false
      })
      return true;
    } catch (err) {
      return false;
    }
  }

  //  Stop Play Stream
  async function stopPlayingStream() {
    trackLog('stopPlayingStream called', 'id:', id!.substring(0, 32))
    zegoExpressEngine.stopPlayingStream(id!.substring(0, 32));
    clearRemoteStream();
  }

  const toggleCommentatorVideo = async (on: boolean) => {
    if (isTogglingCommentatorVideo) {
      return
    }
    setIsTogglingCommentatorVideo(true)
    await zegoExpressEngine.enableVideoCaptureDevice(localStreamRef.current!, on)
    setHasCommentatorVideoOn(on)
    setIsTogglingCommentatorVideo(false)
  }

  const updateLiveUserCount = () => {
    zim.queryRoomOnlineMemberCount(roomId.substring(0, 32)!)
      .then(function ({ roomID, count }) {
        setRoomLiveUserCount(count)
      })
      .catch(function (err) {
        console.error("failed to fetch live audience count: " + err.message)
      });
  }

  useEffect(() => {
    if (!isCreatingBet) {
      setInCreateBetDisplayName("")
      setInCreateBetOptions(["", ""])
    }
  }, [isCreatingBet]);

  useEffect(() => {
    updateAfterBetAmount(selectedBetOptionIndex, selectedBetId)
  }, [placedBetAmount]);

  const updateAfterBetAmount = (optionIndex: number, userSelectedBetId: string) => {
    if (optionIndex < 0) {
      return
    }

    const selectedBet = bets.find((bet) => bet.id === userSelectedBetId)
    if (!selectedBet) {
      return
    }

    if (isNaN(placedBetAmount)) {
      return
    }

    let betTVLSum = 0
    let otherOptionsBetTVLSum = 0
    for (let i = 0; i < selectedBet.optionTVL.length; i++) {
      betTVLSum += selectedBet.optionTVL[i]

      if (i !== optionIndex) {
        otherOptionsBetTVLSum += selectedBet.optionTVL[i]
      }
    }

    let price = (selectedBet.optionTVL[optionIndex] + placedBetAmount) / (betTVLSum + placedBetAmount)
    if (otherOptionsBetTVLSum === 0) {
      price = 1 / selectedBet.optionTVL.length
    }

    const shares = placedBetAmount / price

    let winningAfterTakeRate
    if (selectedBet.betType === BetType.DECISION) {
      winningAfterTakeRate = 1 - overallDecisionTakeRate
    } else {
      winningAfterTakeRate = 1 - overallTakeRate
    }

    setAfterBetWinningCoinsAmount(placedBetAmount * winningAfterTakeRate + shares / (selectedBet.optionShares[optionIndex] + shares) *
      otherOptionsBetTVLSum * winningAfterTakeRate)
  }

  useEffect(() => {
    if (popupContainerRef.current) {
      popupContainerRef.current.scrollTop = popupContainerRef.current.scrollHeight;
    }
  }, [inCreateBetOptions]);

  useEffect(() => {
    scrollChatToBottom()
  }, [chatMessages, isShowingTrendingBet]);

  const scrollChatToBottom = () => {
    if (chatMessages && streamerRoomChatListRef.current) {
      streamerRoomChatListRef.current.scrollTop = streamerRoomChatListRef.current.scrollHeight;
    }
  }

  const sendSuperChat = () => {
    if (!isUserLoggedIn()) {
      login()
      return
    }

    // TODO: add tutorial popup
    if (!chatBoxContent || chatBoxContent.trim() === '') {
      toast.error("Can't send empty super chat!")
      return
    }

    const hasSeenTutorial = localStorage.getItem(HAS_SEEN_SUPERCHAT_TUTORIAL_POPUP_KEY)
    if (!hasSeenTutorial || hasSeenTutorial !== 'true') {
      setIsShowingSuperChatTutorialPopup(true)
      localStorage.setItem(HAS_SEEN_SUPERCHAT_TUTORIAL_POPUP_KEY, 'true')
      return
    }

    setIsShowingSuperChatTutorialPopup(false)

    HttpClient.post<Response<PlaceBetResponse>>('room/sendSuperChat', {
      roomId: id,
      message: chatBoxContent.trim()
    })
      .then((response) => {
        setUserCoins(response.data.userBalance)
        setUserPoints(response.data.userPoints)
        setChatBoxContent("")
        mixpanel.track('USER_SUPERCHAT')
      })
      .catch((e): any => {
        console.log("!!!!failed to create bet", { e });
        toast.error(e.response?.data?.errorMessage ?? "Failed to place bet. Try again.");
      })
  }

  const sendChatMessage = () => {
    const isUserLoggedin = isUserLoggedIn()
    let userOrGuestDisplayName = displayName
    let userOrGuestPfpUrl = profilePicUrl
    if (!isUserLoggedin) {
      userOrGuestDisplayName = localStorage.getItem(GUEST_DISPLAY_NAME_KEY)
      userOrGuestPfpUrl = "https://songmate-public.s3.amazonaws.com/pfp/default_pfp.png"
    }

    if (!chatBoxContent || chatBoxContent.trim() === '') {
      toast.error("Can't send empty chat!")
      return
    }

    let toConversationID = roomId.substring(0, 32)!; // Peer user's ID. 
    let conversationType = 1; // Conversation type, 1-on-1 chat: 0. In-room chat: 1. Group chat: 2. 
    let config = {
      priority: 3, // Set message priority. Low: 1 (by default). Medium: 2. High: 3. 
    };

    let messageTextObj = {
      type: 1, message: JSON.stringify({
        text: chatBoxContent,
        messageType: MessageBodyType.CHAT_MESSAGE,
        userDisplayName: userOrGuestDisplayName,
        userPfpUrl: userOrGuestPfpUrl,
        senderLiveBetId: userAddress
      } as MessageBody)
    };

    var notification = {
      onMessageAttached: function (message: any) {
        console.log("message sent notification", { message })
      }
    }

    zim.sendMessage(messageTextObj, toConversationID, conversationType, config, notification)
      .then(function ({ message }) {
        console.log("message sent", { message })
        setChatBoxContent("")
        handleMessage([message], userAddress)
      })
      .catch(function (err) {
        console.log(err)
        if (err.code === 6000322 || err.code === 6000203) {
          zim.enterRoom({ roomID: roomId.substring(0, 32)!, roomName: roomId.substring(0, 32)! }, {} as ZIMRoomAdvancedConfig)
            .then(() => {
              console.log("Logged into room " + id)
              zim.sendMessage(messageTextObj, toConversationID, conversationType, config, notification)
                .then(function ({ message }) {
                  console.log("message sent", { message })
                  setChatBoxContent("")
                  handleMessage([message], userAddress)
                })
                .catch((e) => {
                  console.log("failed to send message again", { e })
                  toast.error("Failed to send chat. Try again. (Reason: " + e.message + '"');
                })
            })
            .catch((e) => {
              console.log("failed to log into room", { e })
              toast.error("Failed to send chat. Try again. (Reason: " + e.message + '"');
            })
        } else {
          toast.error("Failed to send chat. Try again.")
        }
      });
  }

  const handleRoomStateChange = (roomStateChangedResult: ZIMEventOfRoomStateChangedResult) => {
    if (roomStateChangedResult.state === 0 /** ZIMRoomState.Disconnected */) {
      console.log("handleRoomStateChange", { state: 'disconnected' })
      enterRoom()
    }
  }

  const handleMessage = (messageList: ZIMMessage[], userAddress: string) => {
    trackLog('handleMessage', messageList[0])

    for (const message of messageList) {
      if (!message || !message.message) {
        continue
      }

      if (!roomIdRef || !roomIdRef.current || (message.conversationID !== roomIdRef.current.substring(0, 32))) {
        console.log('skip syncing message from other conversations', {
          messageConversationId: message.conversationID,
          roomId: roomId
        })
        continue
      }
      const decodedMessageString = decodeURIComponent(message.message as string)

      console.log("decodedMessageString", { decodedMessageString })

      const messageBody = JSON.parse(decodedMessageString) as MessageBody
      let userPfpUrl = messageBody.userPfpUrl
      let bulletMessageText = messageBody.text ?? ""
      let shouldSkipBulletMessage = false
      if (messageBody.messageType === MessageBodyType.SEND_GIFT) {
        if (messageBody.type === GIFT_TYPE.WIF_STICKER) {
          userPfpUrl = window.location.protocol + "//" + window.location.host
          if (messageBody.subtype === STICKER_SUB_TYPE.WIF_SPACEMAN) {
            userPfpUrl += "/wif_space.webp"
            shouldSkipBulletMessage = true
            launchRocket()
          } else if (messageBody.subtype === STICKER_SUB_TYPE.WIF_ANGER) {
            userPfpUrl += "/wif_angry.gif"
          } else if (messageBody.subtype === STICKER_SUB_TYPE.WIF_BELIEVE) {
            userPfpUrl += "/wif_believe.webp"
          } else if (messageBody.subtype === STICKER_SUB_TYPE.WIF_LOL) {
            userPfpUrl += "/wif_lol.webp"
          } else if (messageBody.subtype === STICKER_SUB_TYPE.WIF_POPCORN) {
            userPfpUrl += "/wif_popcorn.gif"
          } else if (messageBody.subtype === STICKER_SUB_TYPE.WIF_LASER) {
            userPfpUrl += "/wif_laser.webp"
          } else if (messageBody.subtype === STICKER_SUB_TYPE.WIF_KING) {
            userPfpUrl += "/wif_king.webp"
          } else if (messageBody.subtype === STICKER_SUB_TYPE.WIF_MONEY) {
            userPfpUrl += "/wif_money.jpg"
            shouldSkipBulletMessage = true
            animateMakeItRain()
          }

          // bulletMessageText = ""
          if (messageBody.recipientId && messageBody.recipientId === userAddress) {
            fetchRoomInfo()
            updateUserCoinBalance()
          }
        } else if (messageBody.type === GIFT_TYPE.GIFT_COIN) {
          const isRecipient = messageBody.recipientId && messageBody.recipientId === userAddress
          if (messageBody.subtype !== IN_APP_USD_FAKE_ADDRESS) {
            // bulletMessageText = ""

            if (isRecipient) {
              fetchRoomInfo()
            }
          } else {
            if (isRecipient) {
              fetchRoomInfo()
              updateUserCoinBalance()
            }
          }
        }
      }

      if (!shouldSkipBulletMessage) {
        handleNewBulletMessage({
          messageId: message.messageID,
          text: bulletMessageText,
          betId: messageBody.betId,
          type: messageBody.messageType,
          userPfpUrl: userPfpUrl,
          userDisplayName: messageBody.userDisplayName ?? "",
          color: messageBody.color,
          createdAt: Math.floor(Date.now() / 1000),
          floor: 0,
          withPadding: (publishingState === PublishingState.PUBLISHING || isPlayingStream == true),
        })
      }

      if (messageBody.messageType === MessageBodyType.RESOLVED_BET) {
        console.log(messageBody.betId)
        if (isUserLoggedIn()) {
          updateUserCoinBalance()
        }
        fetchRoomInfo()
        setChatMessages((prevChatMessages) => {
          const newMessages = [...prevChatMessages, {
            messageId: message.messageID,
            text: messageBody.text,
            betId: messageBody.betId,
            type: messageBody.messageType
          } as ChatMessage]
          return newMessages
        })
      } else if (messageBody.messageType === MessageBodyType.PLACED_BET) {
        console.log(messageBody.betId)
        fetchRoomInfo()
        setChatMessages((prevChatMessages) => {
          const newMessages = [...prevChatMessages, {
            messageId: message.messageID,
            text: messageBody.text,
            betId: messageBody.betId,
            type: messageBody.messageType
          } as ChatMessage]
          return newMessages
        })
      } else if (messageBody.messageType === MessageBodyType.ADD_BET) {
        console.log(messageBody.betId)
        fetchRoomInfo()
        setChatMessages((prevChatMessages) => {
          const newMessages = [...prevChatMessages, {
            messageId: message.messageID,
            text: messageBody.text,
            betId: messageBody.betId,
            type: messageBody.messageType
          } as ChatMessage]
          return newMessages
        })
      } else if (messageBody.messageType === MessageBodyType.CHAT_MESSAGE) {
        setChatMessages((prevChatMessages) => {
          const newMessages = [...prevChatMessages, {
            messageId: message.messageID,
            text: messageBody.userDisplayName + ": " + messageBody.text,
            userPfpUrl: messageBody.userPfpUrl,
            type: messageBody.messageType
          } as ChatMessage]
          return newMessages
        })
      } else if (messageBody.messageType === MessageBodyType.SEND_SUPER_CHAT) {
        setChatMessages((prevChatMessages) => {
          const newMessages = [...prevChatMessages, {
            messageId: message.messageID,
            text: messageBody.userDisplayName + ": " + messageBody.text,
            userPfpUrl: messageBody.userPfpUrl,
            type: messageBody.messageType,
            color: messageBody.color
          } as ChatMessage]
          return newMessages
        })
      } else if (messageBody.messageType === MessageBodyType.TRENDING_BET) {
        fetchRoomInfo()
        setChatMessages((prevChatMessages) => {
          const newMessages = [...prevChatMessages, {
            messageId: message.messageID,
            text: messageBody.text,
            type: messageBody.messageType
          } as ChatMessage]
          return newMessages
        })
      } else if (messageBody.messageType === MessageBodyType.UPDATE_STREAM_LINK) {
        fetchRoomInfo()
        setChatMessages((prevChatMessages) => {
          const newMessages = [...prevChatMessages, {
            messageId: message.messageID,
            text: messageBody.text,
            type: messageBody.messageType
          } as ChatMessage]
          return newMessages
        })
      } else if (messageBody.messageType === MessageBodyType.STOP_BET) {
        fetchRoomInfo()
        setChatMessages((prevChatMessages) => {
          const newMessages = [...prevChatMessages, {
            messageId: message.messageID,
            text: messageBody.text,
            type: messageBody.messageType
          } as ChatMessage]
          return newMessages
        })
      } else if (messageBody.messageType === MessageBodyType.SEND_GIFT) {
        if (streamCommentatorId === userAddress) {
          if (isUserLoggedIn()) {
            updateUserCoinBalance()
          }
        }

        setChatMessages((prevChatMessages) => {
          const newMessages = [...prevChatMessages, {
            messageId: message.messageID,
            text: messageBody.text,
            userPfpUrl: userPfpUrl,
            type: messageBody.messageType
          } as ChatMessage]
          return newMessages
        })
      } else if (messageBody.messageType === MessageBodyType.UPDATE_COMMENTATOR) {
        fetchRoomInfo()
        setChatMessages((prevChatMessages) => {
          const newMessages = [...prevChatMessages, {
            messageId: message.messageID,
            text: messageBody.text,
            type: messageBody.messageType
          } as ChatMessage]
          return newMessages
        })
      } else if (messageBody.messageType === MessageBodyType.ADD_TOP_COMMENT) {
        console.log(messageBody.betId)
        fetchRoomInfo()
        setChatMessages((prevChatMessages) => {
          const newMessages = [...prevChatMessages, {
            messageId: message.messageID,
            text: messageBody.text,
            betId: messageBody.betId,
            type: messageBody.messageType
          } as ChatMessage]
          return newMessages
        })
      } else if (messageBody.messageType === MessageBodyType.CREATE_AIRDROP) {
        setChatMessages((prevChatMessages) => {
          const newMessages = [...prevChatMessages, {
            messageId: message.messageID,
            userPfpUrl: messageBody.userPfpUrl,
            text: messageBody.text,
            type: messageBody.messageType,
            airdropId: messageBody.airdropId,
            expirationTimestamp: messageBody.expirationTimestamp,
            airdropClaimStatus: AirdropClaimStatus.UNCLAIMED
          } as ChatMessage]
          return newMessages
        })
      } else if (messageBody.messageType === MessageBodyType.CLAIM_AIRDROP) {
        setChatMessages((prevChatMessages) => {
          const newMessages = [...prevChatMessages, {
            messageId: message.messageID,
            userPfpUrl: messageBody.userPfpUrl,
            text: messageBody.text,
          } as ChatMessage]
          updateAirdropStatus(newMessages, AirdropClaimStatus.CLAIMED, messageBody.airdropId, messageBody.recipientId)

          return newMessages
        })
      } else if (messageBody.messageType === MessageBodyType.RESOLVE_AIRDROP) {
        setChatMessages((prevChatMessages) => {
          const newMessages = [...prevChatMessages, {
            messageId: message.messageID,
            userPfpUrl: messageBody.userPfpUrl,
            text: messageBody.text,
          } as ChatMessage]
          updateAirdropStatus(newMessages, AirdropClaimStatus.EXPIRED, messageBody.airdropId, undefined)

          return newMessages
        })

        if (messageBody.claimedUserAddress?.includes(userAddress)) {
          fetchRoomInfo()
        }
      }
    }
  }

  const updateAirdropStatus = (messages: ChatMessage[], airdropClaimStatus: AirdropClaimStatus,
    airdropId?: string, recipientId?: string) => {
    if (!airdropId) {
      return
    }

    if (airdropClaimStatus === AirdropClaimStatus.CLAIMED && (!recipientId || (recipientId !== userAddress))) {
      console.log("skipping updateAirdropStatus CLAIMED as current user didn't claim the airdrop")
      return
    }

    for (const message of messages) {
      if (message.airdropId === airdropId && message.type === MessageBodyType.CREATE_AIRDROP) {
        message.airdropClaimStatus = airdropClaimStatus
        break
      }
    }
  }

  const animateMakeItRain = () => {
    trackLog('animateMakeItRain called')
    if (rainTimeoutId.current) {
      trackLog('reset previous timeout')
      clearTimeout(rainTimeoutId.current);
    }
    setIsRaining(true);

    rainTimeoutId.current = setTimeout(() => {
      setIsRaining(false);
      rainTimeoutId.current = null;
    }, rainDuration);
  }

  const sendGift = (type: string, subtype: string) => {
    if (!isUserLoggedIn()) {
      login()
      return
    }

    if (userAddress === streamCommentatorId) {
      toast.error("You can't gift yourself.")
      return
    }

    setGiftingSelectedSubtype(subtype)
    const hasSeenTutorial = localStorage.getItem(HAS_SEEN_GIFTING_STICKER_TUTORIAL_POPUP_KEY)
    if (!hasSeenTutorial || hasSeenTutorial !== 'true') {
      setIsShowingGiftingRocketTutorialPopup(true)
      localStorage.setItem(HAS_SEEN_GIFTING_STICKER_TUTORIAL_POPUP_KEY, 'true')
      return
    }

    setIsShowingGiftingRocketTutorialPopup(false)

    HttpClient.post<Response<PlaceBetResponse>>('room/sendGift', {
      roomId: id,
      type: type,
      subtype: subtype
    })
      .then((response) => {
        setUserCoins(response.data.userBalance)
        setUserPoints(response.data.userPoints)
        mixpanel.track('USER_GIFT', { type: type })
      })
      .catch((e): any => {
        console.log("!!!!failed to create bet", { e });
        toast.error(e.response?.data?.errorMessage ?? "Failed to place bet. Try again.");
      })
  }

  const showGiftMemecoinPopup = async () => {
    if (!isUserLoggedIn()) {
      login()
      return
    }

    try {
      setGiftPopupIsLoading(true)
      setIsShowingGiftPopup(true)
      const parsedTokenAccounts = await connection.getParsedTokenAccountsByOwner(new PublicKey(userAddress), {
        "programId": TOKEN_PROGRAM_ID
      })

      const tokenAddressToInfo: { [key: string]: TokenInfo } = {}
      const tokenAddresses: string[] = []
      parsedTokenAccounts.value.map((info) => {
        const tokenInfo = info.account.data.parsed.info
        const address = tokenInfo.mint
        const uiBalance = tokenInfo.tokenAmount.uiAmountString

        if (address === USDCAddress || parseFloat(uiBalance) >= 1) {
          tokenAddresses.push(address)
          tokenAddressToInfo[tokenInfo.mint] = {
            address: address,
            balance: tokenInfo.tokenAmount.amount,
            decimals: tokenInfo.tokenAmount.decimals,
            uiBalance: uiBalance
          }
        }
      })

      await updateUserCoinBalance()

      let tokens = []
      if (tokenAddresses.length > 0) {
        const response = await axios.post(RPC_URL, {
          "jsonrpc": "2.0", "id": 1, "method": "getAssets", "params": { "ids": tokenAddresses }
        })

        tokens = response.data.result
      }

      const validTokensInfo: { [key: string]: TokenInfo } = {}

      validTokensInfo[IN_APP_USD_FAKE_ADDRESS] = {
        address: IN_APP_USD_FAKE_ADDRESS,
        symbol: 'USD',
        name: 'USD',
        logo: 'https://songmate-public.s3.us-east-1.amazonaws.com/coin.png',
        decimals: 1,
        balance: String(userCoins),
        uiBalance: String(userCoins),
      }

      for (let i = 0; i < tokenAddresses.length; i++) {
        const tokenAddress = tokenAddresses[i]
        const token = tokens[i]
        const metadata = token.content.metadata
        const logo = token.content.links.image
        const isFungibleToken = (metadata.token_standard === "FungibleAsset" || metadata.token_standard === "Fungible")
        if (logo && isFungibleToken) {
          validTokensInfo[tokenAddress] = {
            address: tokenAddress,
            symbol: metadata.symbol,
            name: metadata.name,
            logo: logo,
            decimals: tokenAddressToInfo[tokenAddress].decimals,
            balance: tokenAddressToInfo[tokenAddress].balance,
            uiBalance: tokenAddressToInfo[tokenAddress].uiBalance,
          }
        }
      }
      trackLog('validTokensInfo', validTokensInfo)
      setGiftPopupInfoList(validTokensInfo)
      setGiftPopupIsLoading(false)

      console.error(validTokensInfo)
    } catch (e) {
      toast.error("Failed to fetch your wallet balance. Please try again.")
      console.error(e)
    }
  }

  const showAirdropTokenPopup = async () => {
    if (!isUserLoggedIn()) {
      login()
      return
    }

    try {
      setAirdropPopupIsLoading(true)
      setIsShowingAirdropPopup(true)
      const parsedTokenAccounts = await connection.getParsedTokenAccountsByOwner(new PublicKey(userAddress), {
        "programId": TOKEN_PROGRAM_ID
      })

      const tokenAddressToInfo: { [key: string]: TokenInfo } = {}
      const tokenAddresses: string[] = []
      parsedTokenAccounts.value.map((info) => {
        const tokenInfo = info.account.data.parsed.info
        const address = tokenInfo.mint
        const uiBalance = tokenInfo.tokenAmount.uiAmountString

        if (address === USDCAddress || parseFloat(uiBalance) >= 1) {
          tokenAddresses.push(address)
          tokenAddressToInfo[tokenInfo.mint] = {
            address: address,
            balance: tokenInfo.tokenAmount.amount,
            decimals: tokenInfo.tokenAmount.decimals,
            uiBalance: uiBalance
          }
        }
      })

      await updateUserCoinBalance()

      let tokens = []
      if (tokenAddresses.length > 0) {
        const response = await axios.post(RPC_URL, {
          "jsonrpc": "2.0", "id": 1, "method": "getAssets", "params": { "ids": tokenAddresses }
        })
        tokens = response.data.result
      }

      const validTokensInfo: { [key: string]: TokenInfo } = {}

      for (let i = 0; i < tokenAddresses.length; i++) {
        const tokenAddress = tokenAddresses[i]
        const token = tokens[i]
        const metadata = token.content.metadata
        const logo = token.content.links.image
        const isFungibleToken = (metadata.token_standard === "FungibleAsset" || metadata.token_standard === "Fungible")
        if (logo && isFungibleToken) {
          validTokensInfo[tokenAddress] = {
            address: tokenAddress,
            symbol: metadata.symbol,
            name: metadata.name,
            logo: logo,
            decimals: tokenAddressToInfo[tokenAddress].decimals,
            balance: tokenAddressToInfo[tokenAddress].balance,
            uiBalance: tokenAddressToInfo[tokenAddress].uiBalance,
          }
        }
      }
      trackLog('validTokensInfo for airdops', validTokensInfo)
      setAirdropPopupInfoList(validTokensInfo)
      setAirdropPopupIsLoading(false)

      console.error(validTokensInfo)
    } catch (e) {
      toast.error("Failed to fetch your wallet balance. Please try again.")
      console.error(e)
    }
  }

  const createBet = (betType: BetType) => {
    if (!isUserLoggedIn()) {
      login()
      return
    }

    setInCreateBetType(betType)
    setIsCreatingBet(true)
  }

  const onEnterPlaceBetSection = (betId: string, optionIndex: number) => {
    if (!isUserLoggedIn()) {
      login()
      return
    }

    selectBetOption(betId, optionIndex)
    updateAfterBetAmount(optionIndex, betId)
  }

  const selectBetOption = (betId: string, optionIndex: number) => {
    setSelectedBetId(betId)
    setSelectedBetOptionIndex(optionIndex)
    setPlacedBetAmount(10)
  }

  const onSharingBet = (betId: string) => {
    setSelectedBetIdForSharing(betId)
    setIsShowingSharePopup(true)
  }

  const onCloseSharingBet = () => {
    setSelectedBetIdForSharing("")
    setIsShowingSharePopup(false)
  }

  const placeBetOnBackend = async () => {
    if (placedBetAmount < 2) {
      toast.error("Please at least bet 2 USD")
      return
    }

    setIsPlacingBetOnBackend(true)
    const selectedBetIdCopy = selectedBetId.valueOf()
    HttpClient.post<Response<PlaceBetResponse>>('bet/make', {
      betId: selectedBetIdCopy,
      roomId: id,
      optionIndex: selectedBetOptionIndex,
      amount: placedBetAmount
    })
      .then((response) => {
        setUserCoins(response.data.userBalance)
        setUserPoints(response.data.userPoints)
        setBets((prevBets) => {
          const updatedBets: Bet[] = [...prevBets]
          for (let i = 0; i < updatedBets.length; i++) {
            if (updatedBets[i].id === selectedBetIdCopy) {
              updatedBets[i] = getBetFromBetResponse(response.data.bet)
            }
          }

          return updatedBets
        })
        if (trendingBet?.id === response.data.bet.betId) {
          setTrendingBet(getBetFromBetResponse(response.data.bet))
        }
        selectBetOption("", -1)
        setIsPlacingBetOnBackend(false)

        mixpanel.track('USER_BETTED', { amount: placedBetAmount })
      })
      .catch((e): any => {
        console.log("!!!!failed to create bet", { e });
        toast.error(e.response?.data?.errorMessage ?? "Failed to place bet. Try again.");
        setIsPlacingBetOnBackend(false)
      })
  }

  const onInCreateBetOptionChanged = (newOptionName: string, index: number) => {
    setInCreateBetOptions((previousOptions) => {
      const newOptions = [...previousOptions];
      newOptions[index] = newOptionName
      return newOptions;
    })
  }

  const onCoinRemovedInCreationFlow = (index: number) => {
    setInCreateBetOptions((previousOptions) => {
      const newOptions = [...previousOptions];
      newOptions.splice(index, 1);
      return newOptions;
    })
  }

  const createBetOnBackend = async (betType: number) => {
    if (!inCreateBetDisplayName || inCreateBetDisplayName.length === 0) {
      toast.error("Bet description can't be empty")
      return
    }

    let filteredBetOptions = []
    for (const betOption of inCreateBetOptions) {
      if (!betOption || betOption.trim().length === 0) {
        continue
      }

      filteredBetOptions.push(betOption.trim())
    }

    if (filteredBetOptions.length < 2) {
      toast.error("Need at least 2 non-empty outcome options")
      return
    }

    if (userCoins < betCreationCost) {
      toast.error("Need " + betCreationCost + " USD to create bet. Top up your account.")
      return
    }

    setIsCreatingBetOnBackend(true)
    HttpClient.post<Response<CreateBetOrStreamResponse>>('bet/create', {
      roomId: id,
      betContent: inCreateBetDisplayName,
      betType: betType,
      optionDisplayNames: filteredBetOptions
    })
      .then((response) => {
        const data = response.data

        fetchRoomInfo()
        setIsCreatingBet(false)
        setIsCreatingBetOnBackend(false)
        setUserCoins(data.userCoins)
        setUserPoints(data.userPoints)
      })
      .catch((e) => {
        console.log("!!!!failed to create bet", { e });
        toast.error(e.message);
        setIsCreatingBetOnBackend(false)
      })
  }

  const markBetAsTrendingOnBackend = () => {
    HttpClient.post<Response<RoomResponse>>('room/trending', {
      roomId: roomId,
      trendingBetId: selectedBetIdForMod
    })
      .then((response) => {
        const data = response.data

        fetchRoomInfo()
        hideMarkTrendingBetPopup()
        setIsShowingTrendingBet(true)
        toast.success("Trending bet updated");
      })
      .catch((e) => {
        console.log("!!!!failed to mark bet as trending", { e });
        toast.error(e.message);
      })
  }

  const stopBetOnBackend = () => {
    const betId = isShowingTrendingBet ? trendingBet!.id : selectedBetIdForMod
    HttpClient.post<Response<RoomResponse>>('bet/stop', {
      betId: betId
    })
      .then((response) => {
        fetchRoomInfo()
        toast.success("Bet stopped");
        hideShowingStopBetPopup()
      })
      .catch((e) => {
        console.log("!!!!failed to resolve bet", { e });
        toast.error(e.message);
      })
  }

  const resolveBetAsTrendingOnBackend = () => {
    const betId = isShowingTrendingBet ? trendingBet!.id : selectedBetIdForMod
    HttpClient.post<Response<RoomResponse>>('bet/resolve', {
      betId: betId,
      winningOptionIndex: selectedResolveOptionIndex
    })
      .then((response) => {
        fetchRoomInfo()
        toast.success("Bet resolved");
        hideResolveBetPopup()
      })
      .catch((e) => {
        console.log("!!!!failed to resolve bet", { e });
        toast.error(e.message);
      })
  }

  const markStreamLiveStatusOnBackend = () => {
    HttpClient.post<Response<UpdateRoomLivenessResponse>>('room/markStreamOver', {
      roomId: roomId,
      isStreamOver: !isStreamOver
    })
      .then((response) => {
        fetchRoomInfo()
        toast.success("Stream marked as " + (response.data.room.isStreamOver ? "offline" : "live"));
        setIsShowingMarkStreamLiveStatusPopup(false)
      })
      .catch((e) => {
        console.log("!!!!failed to markStreamLiveStatusOnBackend", { e });
        toast.error(e.message);
      })
  }

  const showUpdateCommentatorPopup = () => {
    setInUpdateCommentatorAddress(streamCommentatorId ?? "")
    setIsShowingUpdateCommentatorPopup(true)
  }

  const updateCommentatorOnBackend = () => {
    HttpClient.post<Response<UpdateRoomCommentatorIdResponse>>('room/update_commentator_id', {
      roomId: roomId,
      commentatorId: inUpdateCommentatorAddress
    })
      .then((response) => {
        toast.success("Stream commentator is set!");
        setStreamCommentatorId(response.data.commentatorId)
        setStreamCommentatorDisplayName(response.data.commentatorDisplayName)
        setStreamCommentatorProfilePicUrl(response.data.commentatorProfilePicUrl)
        setIsShowingUpdateCommentatorPopup(false)
      })
      .catch((e) => {
        console.log("!!!!failed to update stream commentator", { e });
        toast.error(e.response.data.errorMessage);
      })
  }

  const calcOddsFromTVL = (betTVL: number, optionTVLs: number[]): string[] => {
    if (betTVL === 0) {
      const odds = Math.round(100 / optionTVLs.length) + "%"
      return optionTVLs.map((_tvl) => odds)
    }

    const odds: string[] = []
    for (const optionTVL of optionTVLs) {
      odds.push(Math.round(100 * optionTVL / betTVL) + "%")
    }
    return odds
  }

  const getBetFromBetResponse = (bet: BetResponse) => {
    const betTVL = bet.optionTVL.reduce((sum, current) => sum + current, 0)
    return {
      id: bet.betId,
      title: bet.betContent,
      tvl: betTVL.toFixed(0),
      tvlAmount: betTVL,
      options: bet.optionDisplayNames,
      resolvedOption: bet.resolvedOption,
      stoppedTakingBets: bet.stoppedTakingBets,
      betType: bet.type,
      createdAt: bet.createdAt,
      userBettedOptions: bet.userBettedOptions,
      odds: calcOddsFromTVL(betTVL, bet.optionTVL),
      optionTVL: bet.optionTVL,
      optionShares: bet.optionShares,
      takeRate: bet.takeRate
    } as Bet
  }

  const fetchRoomInfo = async () => {
    trackLog('fetchRoomInfo called', id)
    HttpClient.post<Response<RoomResponse>>('room', { roomId: id })
      .then(async (response) => {
        const data = response.data
        console.log("fetchRoomInfo", { data })
        trackLog('setting roomId', data.roomId)
        setRoomId(() => data.roomId)
        setStreamerProfilePicUrl(data.streamerProfilePicUrl ?? '')
        setStreamerDisplayName(data.streamerDisplayName ?? '')
        setIsInitiallyStreaming(data.isStreamingNatively ? true : false)
        setStreamId((data.newStreamId && data.newStreamId !== "") ? data.newStreamId : data.roomId)
        setStreamTitle(data.streamTitle)
        setStreamCreatorId(data.creatorId)
        setStreamCommentatorId(() => data.commentatorId)
        setStreamCommentatorDisplayName(data.commentatorDisplayName)
        setStreamCommentatorProfilePicUrl(data.commentatorProfilePicUrl)
        setUserUsdEarnings(data.userUsdEarnings)
        setInUpdateCommentatorAddress(data.commentatorId ?? "")
        setBets(data.bets.map((bet) => getBetFromBetResponse(bet)))
        setTrendingBet(data.trendingBet ? getBetFromBetResponse(data.trendingBet) : undefined)
        setIsStreamOver(data.isStreamOver ?? false)
        setStreamStartTime(data.streamStartTime)

        if (data.streamerCoinTips) {
          const newStreamerCoinAddresses = Object.keys(data.streamerCoinTips)
          let tokens = []
          if (newStreamerCoinAddresses.length > 0) {
            const response = await axios.post(RPC_URL, {
              "jsonrpc": "2.0", "id": 1, "method": "getAssets", "params": { "ids": newStreamerCoinAddresses }
            })

            tokens = response.data.result
          }

          const newStreamerCoinTips: TokenInfo[] = []

          for (let i = 0; i < newStreamerCoinAddresses.length; i++) {
            const tokenAddress = newStreamerCoinAddresses[i]
            const amount = data.streamerCoinTips[tokenAddress]
            const token = tokens[i]
            const metadata = token.content.metadata
            newStreamerCoinTips.push({
              address: tokenAddress,
              symbol: metadata.symbol,
              name: metadata.name,
              balance: amount,
              uiBalance: parseFloat(parseFloat(amount).toFixed(2)).toString(),
              logo: token.content.links.image,
            })
          }

          setStreamerCoinTips((prevStreamCoinTips) => {
            return newStreamerCoinTips
          })
        } else if (typeof streamerCoinTips == 'undefined') {
          // init setStreamerCoinTips if empty
          setStreamerCoinTips([])
        }
        setTimeout(() => {
          setIsRoomInitializedForCoinAnimation(true)
        }, 10);
      })
      .catch((e) => {
        console.log("getBetFromBetResponse failed", e)
        toast.error("Failed to refresh. Try again (Reason: " + e.message + '"');
      })
  }
  const rocketContainerRef = useRef<HTMLDivElement>(null)
  const { launchRocket, isLaunchingRocket } = useRocketLaunch(rocketContainerRef)

  const handleChatInputKeyDown = (event: any) => {
    if (event.key === 'Enter' && !event.shiftKey) {
      sendChatMessage()
    }
  }

  const cancelPlacingBet = () => {
    setSelectedBetId("")
    setSelectedBetOptionIndex(-1)
  }

  const showResolveBetPopup = (betId: string) => {
    setIsResolvingBet(true)
    setSelectedBetIdForMod(betId)
  }

  const hideResolveBetPopup = () => {
    setIsResolvingBet(false)
    setSelectedResolveOptionIndex(0)
    setSelectedBetIdForMod("")
  }

  const showMarkTrendingBetPopup = (betId: string) => {
    setIsMarkingTrendingBet(true)
    setSelectedBetIdForMod(betId)
  }

  const hideMarkTrendingBetPopup = () => {
    setIsMarkingTrendingBet(false)
    setSelectedBetIdForMod("")
  }

  const showingStopBetPopup = (betId: string) => {
    setIsShowingStopBetPopup(true)
    setSelectedBetIdForMod(betId)
  }

  const hideShowingStopBetPopup = () => {
    setIsShowingStopBetPopup(false)
    setSelectedBetIdForMod("")
  }

  const copyShareLinkAddress = async (link: string) => {
    await navigator.clipboard.writeText(link)
    toast.success("Link copied!")
  }

  const abortingCreatingStream = () => {
    setIsShowingSwapStreamLinkPopup(false)
    setInCreateSwapStreamUrl("")
  }

  const abortingReportStream = () => {
    setIsShowingReportStreamPopup(false)
    setInCreateReportStreamReason("")
  }

  const reportStreamLinkOnBackend = async () => {
    if (!isUserLoggedIn()) {
      login()
      return
    }

    if (!inCreateReportStreamReason || inCreateReportStreamReason === "") {
      toast.error("Report reason can't be empty")
      return
    }

    await HttpClient.post<Response<UpdateStreamResponse>>('room/report', {
      roomId: roomId,
      reason: inCreateReportStreamReason
    })
      .then((response) => {
        const data = response.data

        abortingReportStream()
        toast.success("Report sucessful! Thank you!")
      })
      .catch((e) => {
        console.log("!!!!report stream link", { e });
        toast.error("Failed to report stream. Try again. (Reason: " + e.message + '"');
      })
  }

  const claimAirdrop = async (airdropId?: string) => {
    if (!airdropId || airdropId === "") {
      toast.error("Airdrop can't be found")
      return
    }

    await HttpClient.post<Response<ClaimAirdropResponse>>('room/airdrop/claim', {
      airdropId: airdropId
    })
      .then((response) => {
        const data = response.data

        toast.success("Airdrop claimed!")
      })
      .catch((e) => {
        console.log("!!!!claim airdrop fail", { e });
        toast.error(e.response?.data?.errorMessage ?? "Failed to claim airdrop. Try again");
      })
  }

  const swapStreamLinkOnBackend = async () => {
    if (!inCreateSwapStreamUrl || inCreateSwapStreamUrl.length === 0) {
      toast.error("Stream link can't be empty")
      return
    }

    const streamLink = getExternalStreamLink(inCreateSwapStreamUrl, toast)
    console.error(streamLink)
    if (!streamLink.inCreateChannelName || !streamLink.inCreateStreamerUserName) {
      return
    }

    await HttpClient.post<Response<UpdateStreamResponse>>('room/update_stream_id', {
      streamerUserName: streamLink.inCreateStreamerUserName,
      channelName: streamLink.inCreateChannelName,
      roomId: roomId
    })
      .then((response) => {
        const data = response.data

        abortingCreatingStream()
        if (data.newStreamId) {
          setStreamId(data.newStreamId)
          toast.success("Swapped stream link!")
        } else {
          toast.error("Failed to swap link. Try again.")
        }
      })
      .catch((e) => {
        console.log("!!!!swap stream link", { e });
        toast.error("Failed to swap link. Try again. (Reason: " + e.message + '"');
      })
  }

  const isModOrStreamHost = () => {
    return isCommunityMod || isResolver || (streamCommentatorId && (streamCommentatorId === userAddress))
  }

  const isMod = () => {
    return isCommunityMod || isResolver
  }

  const getBetsUI = (betsToDisplay: Bet[], isTrendingBet: boolean) => {
    return betsToDisplay.map((bet, betIndex) => (
      <div id={"betid~" + bet.id} key={"betid~" + bet.id}>
        {
          (selectedBetId === bet.id) ? (
            <div className="betItemContainer">
              <div className="selectedBetItemHeader">
                <div>{bet.options[selectedBetOptionIndex]}</div>
                <img onClick={cancelPlacingBet} className="placeBetCloseIcon" src="/close.svg" alt="Close"></img>
              </div>
              <div className="selectedBetInputContainer">
                <input type="number" className="selectedBetAmountInput" onKeyDown={(evt) => evt.key === '.' && evt.preventDefault()} value={placedBetAmount} onChange={e => setPlacedBetAmount(parseFloat(e.target.value))} placeholder="10"></input>
                <div className="buttonContainer">
                  <button className="btn selectedBetPlus" onClick={() => setPlacedBetAmount(placedBetAmount + 1)}>+1</button>
                  <button className="btn selectedBetPlus" onClick={() => setPlacedBetAmount(placedBetAmount + 10)}>+10</button>
                </div>
              </div>
              <div className="afterBetWinTicketsContainer">
                <span>estimated to win </span>
                <img className="betBoxToWinCoinImage" src="/coin.png" />
                <span>{afterBetWinningCoinsAmount.toFixed(1)}</span>
              </div>
              <button disabled={isPlacingBetOnBackend} onClick={placeBetOnBackend} className="btn placeBetBtn">
                <div className="placeBetHintContainer">
                  {isPlacingBetOnBackend ? "Placing Bet..." : "Place Bet"}
                </div>
              </button>
            </div>
          ) : (
            <div className="betItemContainer">
              {
                bet.betType === BetType.DECISION ? (
                  <div className="betTitleDecisionContainer">
                    <div className="navbarCoinCointainer" data-tooltip-id="stream-decision-explainer">
                      <div className="betTitleDecisionHintTrigger">[Decision❔] </div>
                      <Tooltip id="stream-decision-explainer" place="bottom" opacity="1" style={{ fontSize: "1rem", borderRadius: "0.8rem" }}>
                        <p>Commentator decides the result.</p>
                        <p>Correct betters split the pot.</p>
                      </Tooltip>
                    </div>
                    <div>{bet.title}</div>
                  </div>
                ) : (
                  <div className="betTitleContainer">
                    {bet.title}
                  </div>
                )
              }
              {
                bet.options.map((option, optionIndex) => (
                  <>
                    <div className="betItemOptionsContainer">
                      <div className="betItemOptionName">{option}</div>
                      <div className="betItemOptionOdds">
                        {
                          (bet.resolvedOption >= 0) ? (
                            (bet.resolvedOption === optionIndex) ? '✅' : '❌'
                          ) : (
                            <>
                              <div className="betItemOptionOddsValue">{bet.odds[optionIndex]}</div>
                              <div className="betItemOptionOddsHint">Odds</div>
                            </>
                          )
                        }
                      </div>
                      {
                        bet.resolvedOption < 0 &&
                        (<button className="btn betBtn" onClick={() => onEnterPlaceBetSection(bet.id, optionIndex)}>Bet</button>)
                      }
                    </div>
                    {
                      bet.userBettedOptions[optionIndex] > 0 && (
                        <div className="userBettedAmount">(You betted {bet.userBettedOptions[optionIndex]} USD)</div>
                      )
                    }
                  </>
                ))
              }
              <div className="betItemFooter">
                <div className="betItemTVL">Total Wagered: <img className="betBoxCoinImage" src="/coin.png" />{bet.tvl}</div>
                {
                  (bet.resolvedOption < 0 && !bet.stoppedTakingBets) ? (
                    <div className="betLivenessContainer">
                      <div className="betLivenessIcon"></div>
                      <div>Live</div>
                      <div className="shareBetContainer" onClick={() => onSharingBet(bet.id)}>
                        <img className="shareBetIcon" src="/share_button.png"></img>
                        <div>Share</div>
                      </div>
                    </div>
                  ) : (
                    <div className="betLivenessContainer">
                      <div>{bet.resolvedOption >= 0 ? "Resolved" : "Pending Resolve"}</div>
                    </div>
                  )
                }
              </div>
              <div className="betItemFooter">

                {
                  (isShowingTrendingBet && roomLiveUserCount && roomLiveUserCount > 0) ? (
                    <div>Live Users: {roomLiveUserCount}</div>
                  ) : (
                    <></>
                  )
                }
                <div className={(isShowingTrendingBet && roomLiveUserCount && roomLiveUserCount > 0) ?
                  "betModFooterRightItems" : "betModFooterLeftItems"
                }>
                  {
                    (isResolver || (bet.betType === BetType.DECISION && userAddress === streamCommentatorId)) && bet.resolvedOption < 0 && (
                      <div className="betModFooterItem" onClick={() => showResolveBetPopup(bet.id)}>
                        <div className="betModFooterItemImage">✅</div>
                        <div>Resolve</div>
                      </div>
                    )
                  }
                  {
                    isResolver && !isShowingTrendingBet && (
                      <div className="betModFooterItem" onClick={() => showMarkTrendingBetPopup(bet.id)}>
                        <img className="betModFooterItemImage" src="/fire.png"></img>
                        <div>Trending</div>
                      </div>
                    )
                  }
                </div>
              </div>
              {
                !isResolver && !isCommunityMod && isTrendingBet && (
                  <div className="betRegularUserFooter">
                    <div className="betModFooterItem" onClick={() => setIsShowingReportStreamPopup(true)}>
                      <div className="betModFooterItemImage">🚨</div>
                      <div className="betModFooterItemText">Report</div>
                    </div>
                  </div>
                )
              }
              {
                isCommunityMod && (
                  <div className="betModFooter">
                    {
                      bet.resolvedOption < 0 && (
                        <>
                          {
                            !isShowingTrendingBet && (
                              <div className="betModFooterItem" onClick={() => showMarkTrendingBetPopup(bet.id)}>
                                <img className="betModFooterItemImage" src="/fire.png"></img>
                                <div>Trending</div>
                              </div>
                            )
                          }
                        </>
                      )
                    }
                    {
                      !bet.stoppedTakingBets && (
                        <div className="betModFooterItem" onClick={() => showingStopBetPopup(bet.id)}>
                          <div className="betModFooterItemImage">🛑</div>
                          <div>Stop</div>
                        </div>
                      )
                    }
                  </div>
                )
              }
            </div>
          )
        }
      </div>
    ))
  }

  return (
    <div className="streamContainer">
      {
        isCreatingBet && (
          <>
            <div className="overlay" onClick={() => setIsCreatingBet(false)}></div>
            <div className="createBetContainer popup" ref={popupContainerRef}>
              <div className="popupHeader">
                <img onClick={() => setIsCreatingBet(false)} className="popupCloseIcon" src="/close.svg" alt="Close"></img>
              </div>
              {(inCreateBetType === BetType.DECISION) ? (
                <>
                  <div className="betDisplayNameContainer">
                    <div>What do you want to decide?</div>
                    <input value={inCreateBetDisplayName} onChange={e => setInCreateBetDisplayName(e.target.value)} className="betDisplayNameInput" placeholder="Which token to buy?"></input>
                  </div>
                  <div className="betOptionsContainer">
                    <div>Outcome Options (need at least 2)</div>
                    {
                      inCreateBetOptions.map((option, index) => (
                        <div className="betOptionItemContainer">
                          <input value={option} onChange={e => onInCreateBetOptionChanged(e.target.value, index)} className="optionNameInput" placeholder={index === 0 ? "GOAT" : "WIF"}></input>
                          <img onClick={() => onCoinRemovedInCreationFlow(index)} className="removeOptionIcon" src="/removeIcon.png"></img>
                        </div>
                      ))
                    }
                    <div onClick={() => onInCreateBetOptionChanged("", inCreateBetOptions.length)} className="addMoreOptions">Add a new option</div>
                  </div>
                  <div className="createBetFooter">
                    <button className="btn create-bet-btn" disabled={isCreatingBetOnBackend} onClick={() => createBetOnBackend(inCreateBetType)}>{isCreatingBetOnBackend ? 'Creating...' : 'Create Decision'}</button>
                    <div className="createBetFeeHint">* Audiences guess what your decision will be. Correct betters split the pot.</div>
                    <div className="lastCreateBetFeeHint">* Commentator earn {(decisionCommentatorTakeRate * 100).toFixed(1)}% fees.</div>
                    <div className="lastCreateBetFeeHint">* Only stream commentator and mods can create a decision.</div>
                  </div>
                </>
              ) : (
                <>
                  <div className="betDisplayNameContainer">
                    <div>What is the bet?</div>
                    <input value={inCreateBetDisplayName} onChange={e => setInCreateBetDisplayName(e.target.value)} className="betDisplayNameInput" placeholder="Can Messi score a goal?"></input>
                  </div>
                  <div className="betOptionsContainer">
                    <div>Outcome Options (need at least 2)</div>
                    {
                      inCreateBetOptions.map((option, index) => (
                        <div className="betOptionItemContainer">
                          <input value={option} onChange={e => onInCreateBetOptionChanged(e.target.value, index)} className="optionNameInput" placeholder={index === 0 ? "Yes" : "No"}></input>
                          <img onClick={() => onCoinRemovedInCreationFlow(index)} className="removeOptionIcon" src="/removeIcon.png"></img>
                        </div>
                      ))
                    }
                    <div onClick={() => onInCreateBetOptionChanged("", inCreateBetOptions.length)} className="addMoreOptions">Add a new option</div>
                  </div>
                  <div className="createBetFooter">
                    <button className="btn create-bet-btn" disabled={isCreatingBetOnBackend} onClick={() => createBetOnBackend(inCreateBetType)}>{isCreatingBetOnBackend ? 'Creating...' : 'Create Bet'}</button>
                    <>
                      <div className="createBetFeeHint">* Costs {betCreationCost} coin. Earn {(betCreationTakeRate * 100).toFixed(1)}% fees of this bet (unless bet gets refunded)</div>
                      <div className="lastCreateBetFeeHint">* Make highly specific questions to increase the likelihood of trending bets.</div>
                      <div className="lastCreateBetFeeHint">* Anyone can create a bet.</div>
                    </>
                  </div>
                </>
              )
              }
            </div>
          </>
        )
      }
      {
        isShowingCommentatorTutorialPopup && (
          <>
            <div className="overlay" onClick={() => setIsShowingCommentatorTutorialPopup(false)}></div>
            <div className="commentatorTutorialPopupContainer popup">
              <div className="popupHeader">
                <img onClick={() => setIsShowingCommentatorTutorialPopup(false)} className="popupCloseIcon" src="/close.svg" alt="Close"></img>
              </div>
              <div className="tutorialTextImportant">
                Please use a headphone.
              </div>
              <div className="commentatorTutorialText">
                Otherwise your audience will hear overlapped sound of your livestream and theirs.
              </div>
              <div className="tutorialCallToActionFooter" onClick={dismissTutorialAndStartCommentatorStream}>[I will wear a headphone. Start streaming.]</div>
            </div>
          </>
        )
      }
      {
        isShowingGiftingRocketTutorialPopup && (
          <>
            <div className="overlay" onClick={() => setIsShowingGiftingRocketTutorialPopup(false)}></div>
            <div className="popup">
              <div className="popupHeader">
                <img onClick={() => setIsShowingGiftingRocketTutorialPopup(false)} className="popupCloseIcon" src="/close.svg" alt="Close"></img>
              </div>
              <div className="tutorialTextImportant">
                Your sticker will be displayed to all audience.
              </div>
              <div className="tutorialTextImportant">
                ${giftPrice[GIFT_TYPE.WIF_STICKER]} will be tipped to the commentator, or the stream creator when there is no commentator.
              </div>
              <div className="tutorialCallToActionFooter" onClick={() => sendGift(GIFT_TYPE.WIF_STICKER, giftingSelectedSubtype!)}>[ Send my sticker now ]</div>
            </div>
          </>
        )
      }
      {
        isShowingSuperChatTutorialPopup && (
          <>
            <div className="overlay" onClick={() => setIsShowingSuperChatTutorialPopup(false)}></div>
            <div className="popup">
              <div className="popupHeader">
                <img onClick={() => setIsShowingSuperChatTutorialPopup(false)} className="popupCloseIcon" src="/close.svg" alt="Close"></img>
              </div>
              <div className="tutorialTextImportant">
                Your chat will be displayed in red color (and costs ${giftPrice[GIFT_TYPE.SUPER_CHAT]}). The tip goes to the commentator, or the stream creator when there is no commentator.
              </div>
              <div className="tutorialCallToActionFooter" onClick={sendSuperChat}>[ Send my super chat now ]</div>
            </div>
          </>
        )
      }
      {
        isShowingSharePopup && (
          <>
            <div className="overlay" onClick={onCloseSharingBet}></div>
            <div className="sharePopupContainer popup" ref={popupContainerRef}>
              <div className="popupHeader">
                <img onClick={onCloseSharingBet} className="popupCloseIcon" src="/close.svg" alt="Close"></img>
              </div>
              <div className="sharePopupBody">
                <div className="twitterShare">
                  <img className="twitterShareIcon" src="/twitter_icon.png"></img>
                  <a className="twitterShareLink"
                    href={`https://twitter.com/intent/tweet?text="${bets.find((bet) => bet.id === selectedBetIdForSharing)?.title}"%0ACome watch and bet live with me on @thelivebet:%0A${window.location.href + '?bid=' + selectedBetIdForSharing}`}>
                    Share on X (Twitter)
                  </a>
                </div>
                <div className="shareUrlContainer">
                  <div className="shareUrlText">{window.location.href + '?bid=' + selectedBetIdForSharing}</div>
                  <div className="btn shareUrlContainerCopyButton" onClick={() => copyShareLinkAddress(window.location.href + '?bid=' + selectedBetIdForSharing)}>copy</div>
                </div>
              </div>
            </div>
          </>
        )
      }
      {
        isMarkingTrendingBet && (
          <>
            <div className="overlay" onClick={hideMarkTrendingBetPopup}></div>
            <div className="markTrendingContainer popup" ref={popupContainerRef}>
              <div className="popupHeader">
                <img onClick={hideMarkTrendingBetPopup} className="popupCloseIcon" src="/close.svg" alt="Close"></img>
              </div>
              <div className="confirmMarkTrendingText">
                Are you sure to mark this bet as trending?
              </div>
              <button className="btn create-bet-btn" onClick={markBetAsTrendingOnBackend}>Confirm</button>
            </div>
          </>
        )
      }
      {
        isShowingStopBetPopup && (
          <>
            <div className="overlay" onClick={hideShowingStopBetPopup}></div>
            <div className="markTrendingContainer popup" ref={popupContainerRef}>
              <div className="popupHeader">
                <img onClick={hideShowingStopBetPopup} className="popupCloseIcon" src="/close.svg" alt="Close"></img>
              </div>
              <div className="confirmMarkTrendingText">
                Are you sure to {bets.find((bet) => bet.id === selectedBetIdForMod)?.stoppedTakingBets ? "resume" : "stop"} this bet?
              </div>
              <button className="btn create-bet-btn" onClick={stopBetOnBackend}>Confirm</button>
            </div>
          </>
        )
      }
      {
        isResolvingBet && (
          <>
            <div className="overlay" onClick={hideResolveBetPopup}></div>
            <div className="resolveBetContainer popup" ref={popupContainerRef}>
              <div className="popupHeader">
                <img onClick={hideResolveBetPopup} className="popupCloseIcon" src="/close.svg" alt="Close"></img>
              </div>
              <div className="confirmMarkTrendingText">
                {
                  (isShowingTrendingBet ? trendingBet : bets.find((bet) => bet.id === selectedBetIdForMod))?.options.map((option, index) => (
                    <button className={selectedResolveOptionIndex === index ? "selectedResolveOptionButton" : "unselectedResolveOptionButton"}
                      onClick={() => setSelectedResolveOptionIndex(index)}>{option}</button>
                  ))
                }
                <button className={selectedResolveOptionIndex === 1000001 ? "selectedResolveOptionButton" : "unselectedResolveOptionButton"}
                  onClick={() => setSelectedResolveOptionIndex(1000001)}>Undecided</button>
              </div>
              <div className="createBetFooter">
                <button className="btn create-bet-btn" onClick={resolveBetAsTrendingOnBackend}>Confirm</button>
              </div>
            </div>
          </>
        )
      }
      {
        isShowingMarkStreamLiveStatusPopup && (
          <>
            <div className="overlay" onClick={() => setIsShowingMarkStreamLiveStatusPopup(false)}></div>
            <div className="markOfflineContainer popup" ref={popupContainerRef}>
              <div className="popupHeader">
                <img onClick={() => setIsShowingMarkStreamLiveStatusPopup(false)} className="popupCloseIcon" src="/close.svg" alt="Close"></img>
              </div>
              <div className="confirmMarkTrendingText">
                Are you sure to mark this bet as {isStreamOver ? "live" : "offline"}?
              </div>
              <div className="createBetFooter">
                <button className="btn create-bet-btn" onClick={markStreamLiveStatusOnBackend}>Confirm</button>
              </div>
            </div>
          </>
        )
      }
      {
        isShowingUpdateCommentatorPopup && (
          <>
            <div className="overlay" onClick={() => setIsShowingUpdateCommentatorPopup(false)}></div>
            <div className="updateCommentatorPopupContainer popup" ref={popupContainerRef}>
              <div className="popupHeader">
                <img onClick={() => setIsShowingUpdateCommentatorPopup(false)} className="popupCloseIcon" src="/close.svg" alt="Close"></img>
              </div>
              <div className="confirmUpdateCommentatorText">
                <div>Host wallet address</div>
                <div className="confirmUpdateCommentatorInputContainer">
                  <input className="inCreateUpdateCommentatorInput" value={inUpdateCommentatorAddress} onChange={e => setInUpdateCommentatorAddress(e.target.value)} placeholder="Enter address..."></input>
                  <div className="useMyAddressAsCommentatorText" onClick={() => setInUpdateCommentatorAddress(userAddress)}>Use My Address</div>
                </div>
              </div>
              <div className="createBetFooter">
                <button className="btn create-bet-btn" onClick={updateCommentatorOnBackend}>Confirm</button>
              </div>
            </div>
          </>
        )
      }
      {
        isShowingSwapStreamLinkPopup && (
          <>
            <>
              <div className="overlay" onClick={abortingCreatingStream} />
              <div className="swapStreamContainer popup">
                <div className="createStreamHeader">
                  <img onClick={abortingCreatingStream} className="createStreamCloseIcon" src="/close.svg" alt="Close"></img>
                </div>
                <div className="streamUserNameContainer">
                  <div>Swap Stream Link</div>
                  <div className="streamPlatformHint">(supports Youtube, Twitch, Twitter, Kick and streamed.su streams)</div>
                  <input value={inCreateSwapStreamUrl} onChange={e => setInCreateSwapStreamUrl(e.target.value)} className="inCreateStreamerUserNameInput" placeholder="https://www.youtube.com/watch?v=Z8UTqxU3Cdo"></input>
                </div>
                <div className="createBetFooter">
                  <button className="btn create-bet-btn" onClick={swapStreamLinkOnBackend}>Confirm</button>
                </div>
              </div>
            </>s
          </>
        )
      }
      {
        isShowingReportStreamPopup && (
          <>
            <>
              <div className="overlay" onClick={abortingReportStream} />
              <div className="reportStreamContainer">
                <div className="createStreamHeader">
                  <img onClick={abortingReportStream} className="createStreamCloseIcon" src="/close.svg" alt="Close"></img>
                </div>
                <div className="streamUserNameContainer">
                  <div>Report Stream</div>
                  <input value={inCreateReportStreamReason} onChange={e => setInCreateReportStreamReason(e.target.value)} className="inCreateStreamerUserNameInput" placeholder="Enter reason..."></input>
                </div>
                <div className="createBetFooter">
                  <button className="btn create-bet-btn" onClick={reportStreamLinkOnBackend}>Report Stream</button>
                </div>
              </div>
            </>s
          </>
        )
      }
      {
        isShowingGiftPopup && (
          <GiftPopup
            connection={connection}
            web3auth={web3auth}
            streamCommentatorId={streamCommentatorId}
            streamCreatorId={streamCreatorId}
            userAddress={userAddress}
            handleClose={setIsShowingGiftPopup}
            isLoading={giftPopupIsLoading}
            updateUserCoinBalance={updateUserCoinBalance}
            roomId={roomId}
            tokenInfoList={giftPopupInfoList}
          />
        )
      }
      {
        isShowingAirdropPopup && (
          <AirdropPopup
            connection={connection}
            web3auth={web3auth}
            userAddress={userAddress}
            handleClose={setIsShowingAirdropPopup}
            isLoading={airdropPopupIsLoading}
            roomId={roomId}
            tokenInfoList={airdropPopupInfoList}
          />
        )
      }
      {
        getStreamType(streamId) === SelectedChannel.FEATURED ? (
          <div style={{ position: "relative" }}>
            <div className="animationCanvas" ref={rocketContainerRef} style={{
              zIndex: isLaunchingRocket ? 999 : -10
            }}></div>
            <Rain isRaining={isRaining} containerRef={rocketContainerRef} />
            <BulletCanvas messages={bulletMessages} />

            <div className="streamPlayer featuredStreamPlayer">
              {
                (streamTitle && streamTitle !== "") && (
                  <div className="countdownTextContainer">{streamTitle}</div>
                )
              }
              {
                streamCommentatorId && (
                  <div className="streamCommentatorInfo">
                    <div>Host:</div>
                    <img src={streamCommentatorProfilePicUrl} />
                    <div>{streamCommentatorDisplayName}</div>
                  </div>
                )
              }
              <div className="countdownTextContainer">
                {
                  formatTimestamp(countdownRemainingTime)
                }
              </div>
              <div className="featuredStreamButtonContainer">
                {
                  isModOrStreamHost() && (getStreamType(streamId)) && (
                    <div style={{ display: 'flex', flexDirection: 'row', gap: '1rem' }}>
                      <div className="addCommentatorOfStream" onClick={() => {
                        startInAppStream()
                      }}>
                        Start Stream
                      </div>
                      <div className="addCommentatorOfStream" onClick={() => setIsShowingSwapStreamLinkPopup(true)}>
                        Link External Stream
                      </div>
                    </div>
                  )
                }
                {
                  getAddToCalendarButton(false, streamTitle!, streamStartTime!, window.location.href)
                }
              </div>
            </div>
          </div>
        ) : (
          <div>

            <div style={{ position: "relative" }}>
              <div className="animationCanvas" ref={rocketContainerRef} style={{
                zIndex: isLaunchingRocket ? 999 : -10
              }}></div>
              <Rain isRaining={isRaining} containerRef={rocketContainerRef} />

              <BulletCanvas messages={bulletMessages} />
              <StreamFrame id={streamId} screenWidth={screenWidth} operationPanel={(streamId) => {

                if (isModOrStreamHost() && (getStreamType(streamId))) {
                  return <div className="streamPlayer">
                    {
                      (publishingState === PublishingState.REQUESTING_PUBLISH) ? (
                        <div>Starting stream...</div>
                      ) : (
                        <div style={{ display: 'flex', flexDirection: 'row', gap: '1rem' }}>
                          <div className="addCommentatorOfStream" onClick={() => {
                            startInAppStream()
                          }}>
                            Start Stream
                          </div>
                          <div className="addCommentatorOfStream" onClick={() => setIsShowingSwapStreamLinkPopup(true)}>
                            Link External Stream
                          </div>
                        </div>
                      )
                    }
                  </div>
                }
                if (streamId != '' && isInitiallyStreaming) {
                  return <div className="streamPlayer"><div className="spinner"></div></div>
                }
                return <div className="streamPlayer">
                  <div style={{ display: 'flex', flexDirection: 'column', gap: 10, alignItems: 'center' }}>
                    <div style={{ fontSize: '1.5rem' }}>{streamTitle}</div>
                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 10 }}>
                      <img src={streamerProfilePicUrl} style={{ width: '30px', height: '30px' }}></img>
                      <div>{streamerDisplayName + ' is offline'}</div>
                    </div>
                  </div>
                </div>

              }} />
              {
                (publishingState === PublishingState.PUBLISHING || publishingState === PublishingState.REQUESTING_PUBLISH) && (
                  <div className={getStreamPlayerClassName(streamId)} ref={localStreamPlayerRef}>
                    {
                      !hasCommentatorVideoOn && (
                        <div className="commentatorPfpInStreamPlayer">
                          <img
                            src={streamerProfilePicUrl}></img>
                          <div>🎙️</div>
                        </div>
                      )
                    }

                  </div>
                )
              }
              {
                isPlayingStream && (
                  <div className={getStreamPlayerClassName(streamId)}>
                    {!props.hasInteracted &&
                      <div style={{ display: "flex", width: "180px", height: "24px", top: "42%", left: "40%", position: "absolute", backgroundColor: 'var(--color-btn)', color: "white", padding: "10px", textAlign: "center", cursor: "pointer", borderRadius: "5px", zIndex: 600, fontSize: '1.3rem', alignItems: 'center', justifyContent: 'center' }}>
                        <div onClick={() => {
                          console.log('#')
                        }}>Unmute Streamer</div>
                      </div>
                    }
                    <div ref={remoteStreamPlayerRef} style={{ width: '100%', height: '100%', }}>
                      {
                        !hasCommentatorVideoOn && (
                          <div className="commentatorPfpInStreamPlayer">
                            <img
                              src={streamerProfilePicUrl}></img>
                            <div>🎙️</div>
                          </div>
                        )
                      }
                    </div>
                  </div>
                )
              }
            </div>
            <div>
              {
                ((publishingState === PublishingState.PUBLISHING) ? (
                  <div className="commentatorToolsContainer">
                    <div className="addCommentatorOfStream" onClick={stopPublishingStream}>
                      <img className="commentatorVideoIcon" src="/stop-sign.png"></img>
                      <div className="commentatorVideoText">{getStreamType(streamId) === SelectedChannel.IN_APP ? "Stop Stream" : "Stop Commentate"}</div>
                    </div>
                    {
                      hasCommentatorVideoOn ? (
                        <div className="addCommentatorOfStream" onClick={() => toggleCommentatorVideo(false)}>
                          <img className="commentatorVideoIcon" src="/no_video.png"></img>
                          <div className="commentatorVideoText">Turn Off Camera</div>
                        </div>
                      ) : (
                        <div className="addCommentatorOfStream" onClick={() => toggleCommentatorVideo(true)}>
                          <img className="commentatorVideoIcon" src="/has_video.png"></img>
                          <div className="commentatorVideoText">Turn On Camera</div>
                        </div>
                      )
                    }
                  </div>
                ) : (
                  (publishingState === PublishingState.REQUESTING_PUBLISH) ? (
                    <div className="addCommentatorOfStream">
                      <div className="commentatorVideoText">🕒 Starting...</div>
                    </div>
                  ) : (
                    (!isModOrStreamHost() || getStreamType(streamId) === SelectedChannel.IN_APP) ? (
                      <></>
                    ) : (
                      <div className="commentatorToolsContainer">
                        <div className="addCommentatorOfStream" onClick={startCommentateStreamOrShowTutorial}>
                          <div className="commentatorVideoText">🎙️ Start Commentate</div>
                        </div>
                        <div className="addCommentatorOfStream" onClick={startInAppStream}>
                          <div className="commentatorVideoText">▶️ Start Stream</div>
                        </div>
                      </div>
                    )
                  )
                )
                )
              }
            </div>
          </div>
        )
      }
      <div className="streamChat">
        <div className="streamTabContainer">
          <div className={isShowingTrendingBet ? "selectedStreamTabContainer" : "streamTabContainerItem"}
            onClick={() => setIsShowingTrendingBet(true)}>
            Trending
          </div>
          <div className={!isShowingTrendingBet ? "selectedStreamTabContainer rightMostStreamTabContainerItem" :
            "streamTabContainerItem rightMostStreamTabContainerItem"}
            onClick={() => setIsShowingTrendingBet(false)}>
            All ({bets.length})
          </div>
        </div>

        <div className="trendingBetContainer" style={{ display: isShowingTrendingBet ? "flex" : "none" }}>
          <div className="trendingBetsContainer">
            {
              bets.length === 0 ? (
                <div className="noBetsAndCreateContainer">
                  <div className="noBetsContainer">No bets yet.</div>
                  <button className="btn" onClick={() => createBet(BetType.REGULAR)}>Create Bet</button>
                </div>
              ) : (
                <div>
                  {
                    getBetsUI(trendingBet ? [trendingBet] : [], true /*isTrendingBet*/)
                  }
                </div>
              )
            }
          </div>
          {
            isModOrStreamHost() && (
              <div className="betModFooter">
                <div className="betModFooterItem" onClick={() => setIsShowingSwapStreamLinkPopup(true)}>
                  <div className="betModFooterItemImage">🔗</div>
                  <span>Link Stream</span>
                </div>
                {(publishingState !== PublishingState.PUBLISHING) && !isPlayingStream && (
                  <div className="betModFooterItem" onClick={showUpdateCommentatorPopup}>
                    <div className="betModFooterItemImage">🎙️</div>
                    <span>Change Host</span>
                  </div>
                )}
                {
                  isMod() && (
                    <div className="betModFooterItem" onClick={() => setIsShowingMarkStreamLiveStatusPopup(true)}>
                      <div className="betModFooterItemImage">📺</div>
                      <span>{isStreamOver ? "Mark Live" : "Mark Offline"}</span>
                    </div>
                  )
                }
              </div>
            )
          }
          <div className="streamerRoomChatList" ref={streamerRoomChatListRef}>
            {
              chatMessages.length > 0 && (
                <div>
                  {
                    chatMessages.map((message) =>
                      <div className="chatMessageItemContainer">
                        {
                          getMessageIcon(message)
                        }
                        {
                          message.type === MessageBodyType.ADD_BET ? (
                            <div className="chatMessageDisplayText">
                              {message.text}
                            </div>
                          ) : (
                            (message.type === MessageBodyType.CREATE_AIRDROP) ? (
                              <ClaimMessage message={message} handleClaim={() => {
                                if (isUserLoggedIn()) {
                                  claimAirdrop(message.airdropId)
                                } else {
                                  login()
                                }
                              }} />
                            ) : (
                              <div style={{ color: (message.color ? message.color : "#ffffff") }} className="chatMessageDisplayText">
                                {message.text}
                              </div>
                            )
                          )
                        }
                      </div>
                    )
                  }
                </div>
              )
            }
          </div>
          <div className="streamInteractionFooter">
            {
              isUserLoggedIn() && <InStreamEarning userUsdEarnings={userUsdEarnings} />
            }
            {
              (streamerCoinTips && streamerCoinTips.length > 0) && <StreamCoinTips streamerCoinTips={streamerCoinTips} isRoomInitializedForCoinAnimation={isRoomInitializedForCoinAnimation} />
            }
            <div className="giftReactionsContainer">
              <img onClick={() => sendGift(GIFT_TYPE.WIF_STICKER, STICKER_SUB_TYPE.WIF_SPACEMAN)} className="giftSendButton" src="/wif_space.webp"></img>
              <img onClick={() => sendGift(GIFT_TYPE.WIF_STICKER, STICKER_SUB_TYPE.WIF_ANGER)} className="giftSendButton" src="/wif_angry.gif"></img>
              <img onClick={() => sendGift(GIFT_TYPE.WIF_STICKER, STICKER_SUB_TYPE.WIF_BELIEVE)} className="giftSendButton" src="/wif_believe.webp"></img>
              <img onClick={() => sendGift(GIFT_TYPE.WIF_STICKER, STICKER_SUB_TYPE.WIF_LOL)} className="giftSendButton" src="/wif_lol.webp"></img>
              <img onClick={() => sendGift(GIFT_TYPE.WIF_STICKER, STICKER_SUB_TYPE.WIF_POPCORN)} className="giftSendButton" src="/wif_popcorn.gif"></img>
              <img onClick={() => sendGift(GIFT_TYPE.WIF_STICKER, STICKER_SUB_TYPE.WIF_MONEY)} className="giftSendButton" src="/wif_money.jpg"></img>
              <img onClick={showGiftMemecoinPopup} className="giftSendButton" src="/gift.png"></img>
              <img onClick={showAirdropTokenPopup} className="giftSendButton" src="/airdrop.png"></img>
            </div>
            <div className="streamerRoomUserToolBox">
              <div className="streamerRoomChatBox">
                <textarea value={chatBoxContent} onKeyDown={handleChatInputKeyDown} onChange={e => setChatBoxContent(e.target.value)}
                  placeholder="Enter chat message..." className="streamerRoomChatInput"></textarea>
                <img onClick={sendChatMessage} className="chatSendButton" src="/sendIcon-white.svg"></img>
              </div>
              <img onClick={sendSuperChat} className="giftSendButton superChatSendButton" src="/sendIcon-gold.svg"></img>
            </div>
            <div className="createBet">
              <button className="btn" onClick={() => createBet(BetType.REGULAR)}>Create Bet</button>
              {
                (isCommunityMod || isResolver || (userAddress === streamCreatorId) || (userAddress === streamCommentatorId)) && (
                  <button className="btn" onClick={() => createBet(BetType.DECISION)}>Create Decision</button>
                )
              }
            </div>
          </div>
        </div>
        <div style={{ display: isShowingTrendingBet ? "none" : "block" }}>
          {
            bets.length === 0 ? (
              <div className="noBetsAndCreateContainerFullPage">
                <div className="noBetsContainer">No bets yet.</div>
                <button className="btn" onClick={() => createBet(BetType.REGULAR)}>Create Bet</button>
              </div>
            ) : (
              <>
                <div className="betsContainer allBetsBetsContainer">
                  {
                    getBetsUI(bets, false /*isTrendingBet*/)
                  }
                </div>
                <div className="createBet">
                  <button className="btn" onClick={() => createBet(BetType.REGULAR)}>Create Bet</button>
                  {
                    (isCommunityMod || isResolver || (userAddress === streamCreatorId) || (userAddress === streamCommentatorId)) && (
                      <button className="btn" onClick={() => createBet(BetType.DECISION)}>Create Decision</button>
                    )
                  }
                </div>
              </>
            )
          }
        </div>

      </div>
    </div>
  );
}

export default StreamView;
